import { Inject, Service } from "typedi";

import { emptyDecoder, filesRequestDecoder, userFileDecoder } from "../decoders";
import { IUploadedFile, UploadRequest } from "../interfaces";
import { METHODS, RequestManager } from "../requestManager";

@Service()
export class FilesApi {
  @Inject()
  private requestManager!: RequestManager;

  public async uploadFileRequest(payload: UploadRequest): Promise<number> {
    return await this.requestManager.createRequest({
      url: "/api/upload/",
      method: METHODS.POST,
      requestConfig: {},
      serverDataDecoder: userFileDecoder,
    })(payload);
  }

  public async uploadFilesRequest(payload: UploadRequest): Promise<Array<IUploadedFile>> {
    return await this.requestManager.createRequest({
      url: "/api/upload/",
      method: METHODS.POST,
      requestConfig: {},
      serverDataDecoder: filesRequestDecoder,
    })(payload);
  }

  public async deleteFileRequest(fileId: number) {
    return await this.requestManager.createRequest({
      url: `/api/upload/files/${fileId}`,
      method: METHODS.DELETE,
      requestConfig: {},
      serverDataDecoder: emptyDecoder,
    })();
  }
}
