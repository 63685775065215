import qs from "qs";
import { Inject, Service } from "typedi";

import { firstVisitReportDateDecoder, reportDecoder, tableDataDecoder } from "../decoders";
import { ISecurityVisitsReportFilters, Pagination } from "../interfaces";
import { METHODS, RequestManager } from "../requestManager";

@Service()
export class SecurityVisitsReportApi {
  @Inject()
  private requestManager!: RequestManager;

  public async getReportsRequest({
    startDate,
    endDate,
    clients,
    departments,
    visitors,
    pageIndex,
    pageSize,
  }: ISecurityVisitsReportFilters & Pagination) {
    const query = qs.stringify({
      sort: "visitDate:desc",
      filters: {
        status: {
          $eq: "COMPLETE",
        },
        visitDate: {
          $gte: startDate,
          $lt: endDate,
        },
        department: {
          id: {
            $in: departments,
          },
          removed: {
            $eq: false,
          },
          client: {
            id: {
              $in: clients,
            },
            removed: {
              $eq: false,
            },
          },
        },
        visitor: {
          id: {
            $in: visitors,
          },
        },
      },
      populate: [
        "visitResult",
        "visitResult.visitResult.securityVisitReport",
        "visitResult.securityVisitReport.bestPractices",
        "employees",
        "visitor",
      ],
      pagination: {
        pageSize: pageSize,
        page: pageIndex,
      },
    });
    return await this.requestManager.createRequest({
      url: `/api/security-visits?${query}`,
      method: METHODS.GET,
      requestConfig: {},
      serverDataDecoder: tableDataDecoder(reportDecoder),
    })();
  }

  public async getFirstSecurityVisitDateRequest() {
    return await this.requestManager.createRequest({
      url: "/api/getFirstSecurityVisitDate",
      method: METHODS.GET,
      requestConfig: {},
      serverDataDecoder: firstVisitReportDateDecoder,
    })();
  }
}
