import qs from "qs";
import { Inject, Service } from "typedi";

import {
  areasForImporvmentListDecoder,
  areasForImprovmentFullDecoder,
  emptyDecoder,
  firstAreasForImprovmentDateDecoder
} from "../decoders";
import {
  AreaForImprovmentStatus,
  AreasForImprovmentDetailRequest,
  AreasForImprovmentRequest,
  IGetAreaForImprovmentListFilters,
  IGetAreaForImprovmentReportFilters,
  Pagination, 
} from "../interfaces";
import { METHODS, RequestManager } from "../requestManager";

@Service()
export class AreasForImprovmentApi {
  @Inject()
  private requestManager!: RequestManager;

  public async getAreasForImprovmentList({
    startDate,
    endDate,
    status,
    applicationCreators,
    executors,
    pageIndex,
    pageSize,
  }: Pagination & IGetAreaForImprovmentListFilters) {
    const query = qs.stringify(
      {
        populate: "deep",
        sort: "createdAt:desc",
        filters: {
          status: {
            $eq: status,
          },
          createdAt: {
            $gte: startDate,
            $lt: endDate,
          },
          executor: {
            id: {
              $in: executors,
            } 
          },
          safety_visit: {
            visitor: {
              id : {
                $in: applicationCreators
              }
            }
          }
        },
        pagination: {
          pageSize,
          page: pageIndex,
        },
      },
      { skipNulls: true },
    );

    const { data, meta } =  await this.requestManager.createRequest({
      url: `/api/areas-for-improvments?${query}`,
      method: METHODS.GET,
      requestConfig: {},
      serverDataDecoder: areasForImporvmentListDecoder,
    })();

    return { rows: data, totalCount: meta.pagination.total };
  }

  public async getAreasForImprovmentReportList({
    startDate,
    endDate,
    status,
    departments,
    applicationCreators,
    executors,
    pageIndex,
    pageSize,
  }: Pagination & IGetAreaForImprovmentReportFilters) {
    const query = qs.stringify(
      {
        populate: "deep",
        sort: "createdAt:desc",
        filters: {
          status: {
            $eq: status,
            $ne: AreaForImprovmentStatus.CANCELED 
          },
          createdAt: {
            $gte: startDate,
            $lt: endDate,
          },
          executor: {
            id: {
              $in: executors,
            } 
          },
          safety_visit: {
            visitor: {
              id : {
                $in: applicationCreators
              }
            },
            department: {
              id: {
                $in: departments,
              }
            },
          }
        },
        pagination: {
          pageSize,
          page: pageIndex,
        },
      },
      { skipNulls: true },
    );

    const { data, meta } =  await this.requestManager.createRequest({
      url: `/api/areas-for-improvments?${query}`,
      method: METHODS.GET,
      requestConfig: {},
      serverDataDecoder: areasForImporvmentListDecoder,
    })();

    return { rows: data, totalCount: meta.pagination.total };
  }
  public async getAreasForImprovmentBySafetyId(id?: string) {
    const query = qs.stringify(
      {
        filters: {
          safety_visit: {
            id: {
              $in: id,
            },
          },
        },
        populate: ["executor", "files", "safety_visit.department"],
      },
    );

    return await this.requestManager.createRequest({
      url: `/api/areas-for-improvments?${query}`,
      method: METHODS.GET,
      requestConfig: {},
      serverDataDecoder: areasForImporvmentListDecoder,
    })();
  }

  public async getAreasForImprovmentByAreaId(id: string) {
    const data =  await this.requestManager.createRequest({
      url: `/api/areas-for-improvments/${id}?populate=deep`,
      method: METHODS.GET,
      requestConfig: {},
      serverDataDecoder: areasForImprovmentFullDecoder,
    })();

    return data;
  }

  public async deleteAreasForImprovment(id: string) {
    return await this.requestManager.createRequest({
      url: `/api/areas-for-improvments/${id}`,
      method: METHODS.DELETE,
      requestConfig: {},
      serverDataDecoder: emptyDecoder,
    })();
  }
  
  public async cancelAreasForImprovment(id: string, reasonCancellation: string) {
    return await this.requestManager.createRequest({
      url: `/api/areas-for-improvments/cancel/${id}`,
      method: METHODS.POST,
      requestConfig: {},
      serverDataDecoder: emptyDecoder,
    })({ body: { reasonCancellation } });
  }

  public async putAreasForImprovmentBySafetyId(data: AreasForImprovmentRequest, safetyId: string) {
    return await this.requestManager.createRequest({
      url: `/api/areas-for-improvments/list/${safetyId}`,
      method: METHODS.PUT,
      requestConfig: {},
      serverDataDecoder: emptyDecoder,
    })(data);
  }
  
  public async createAreasForImprovment(data: AreasForImprovmentRequest, safetyId: string) {
    return await this.requestManager.createRequest({
      url: `/api/areas-for-improvments/list/${safetyId}`,
      method: METHODS.POST,
      requestConfig: {},
      serverDataDecoder: emptyDecoder,
    })(data);
  }
  public async putAreasForImprovment(data: AreasForImprovmentDetailRequest, id: string) {
    return await this.requestManager.createRequest({
      url: `/api/areas-for-improvments/${id}`,
      method: METHODS.PUT,
      requestConfig: {},
      serverDataDecoder: emptyDecoder,
    })(data);
  }

  public async getFirstAreasForImprovmentDateRequest() {
    return await this.requestManager.createRequest({
      url: "/api/areas-for-improvments/getFirstCreatedDate",
      method: METHODS.GET,
      requestConfig: {},
      serverDataDecoder: firstAreasForImprovmentDateDecoder
    })();
  }
}
