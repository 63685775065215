import { ApplicationStatus, ApplicationType } from "./applicationsList";
import { Guid, ICompletionSteps, IUploadedFile, Request } from "./common";

export interface IApplicationCompletionData {
  staticData: IApplicationStaticData;
  dynamicData: IApplicationCompletionDynamicData;
  stepData: ICompletionSteps<ApplicationCompletionSteps>;
}

export interface IApplicationStaticData {
  id: Guid;
  department: string;
  executors: {
    ru: string;
    en: string;
  }[];
  status: ApplicationStatus;
  type: ApplicationType;
  riskLevel: {
    ru: string;
    en: string;
  };
  riskDescription: {
    ru: string;
    en: string;
  };
  discrepancyDescription: string;
  hazardLocation: string;
  hazardDescription: string;
  hazardPhotos: Array<IUploadedFile>;
}

export interface IApplicationCompletionRadioInputs {
  isDangerResolved: boolean;
  isTheSignUp: boolean;
  areMeasuresHelped: boolean;
}

export interface IApplicationCompletionTextInputs {
  appliedMeasures: string;
  completedActions: string;
}

export interface IApplicationCompletionFileInputs {
  resolvedDangerPhotos: Array<IUploadedFile>;
}

export type IApplicationCompletionDynamicData = IApplicationCompletionRadioInputs &
  IApplicationCompletionFileInputs &
  IApplicationCompletionTextInputs;

export enum ApplicationCompletionSteps {
  NEWDANGER = "NEWDANGER",
  DANGERIS = "DANGERIS",
  ACTIONS = "ACTIONS",
}

export type ICurrentStepPutDto<T> = Request<{ body: { data: { currentStep: T } } }>;

export type ICompletedStepPutDto<T> = Request<{
  body: { data: { completedStep: T } };
}>;

export type IApplicationResultPutDto = Request<{
  body: { data: { applicationResult: IApplicationCompletionDynamicData } };
}>;
