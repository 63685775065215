import { field, number, string, succeed } from "jsonous";

import { ApplicationStatus, ApplicationType } from "../interfaces";

import { applicationResultDecoder } from "./application";
import {
  commonUserDecoder,
  commonUsersDecoder,
  departmentCommonDecoder,
  photosDecoder,
  riskDescriptionDecoder,
  riskLevelDecoder,
} from "./common";
import { enumDecoder, orDefaultDecoder } from "./utils";

const applicationAttributesDecoder = succeed({})
  .assign("type", field("type", enumDecoder(ApplicationType)))
  .assign("department", field("department", departmentCommonDecoder))
  .assign("status", field("status", enumDecoder(ApplicationStatus)))
  .assign("createdAt", field("createdAt", string))
  .assign("executors", field("executors", commonUsersDecoder))
  .assign("applicationNumber", field("applicationNumber", string))
  .assign("discrepancyDescription", field("discrepancyDescription", string))
  .assign("applicationCreator", field("applicationCreator", commonUserDecoder))
  .assign("hazardLocation", field("hazardLocation", string))
  .assign("hazardDescription", field("hazardDescription", string))
  .assign("hazardPhotos", field("hazardPhotos", photosDecoder))
  .assign("applicationResult", field("applicationResult", orDefaultDecoder(applicationResultDecoder, null)))
  .assign("riskLevel", field("riskLevel", riskLevelDecoder))
  .assign("riskDescription", field("riskDescription", riskDescriptionDecoder))
  .assign("inWorkDate", field("inWorkDate", orDefaultDecoder(string, null)))
  .assign("completeDate", field("completeDate", orDefaultDecoder(string, null)))
  .assign("applicationResult", field("applicationResult", orDefaultDecoder(applicationResultDecoder, null)))
  .map((attributes) => ({
    ...attributes,
    executors: attributes.executors.map((executor) => ({
      id: String(executor.id),
      ru: `${executor.lastNameRu} ${executor.firstNameRu} ${executor.middleNameRu ? executor.middleNameRu : ""}`,
      en: `${executor.lastNameEn} ${executor.firstNameEn} ${executor.middleNameEn ? executor.middleNameEn : ""}`,
    })),
    applicationCreator: {
      id: String(attributes.applicationCreator.id),
      ru: `${attributes.applicationCreator.lastNameRu} ${attributes.applicationCreator.firstNameRu} ${
        attributes.applicationCreator.middleNameRu ? attributes.applicationCreator.middleNameRu : ""
      }`,
      en: `${attributes.applicationCreator.lastNameEn} ${attributes.applicationCreator.firstNameEn} ${
        attributes.applicationCreator.middleNameEn ? attributes.applicationCreator.middleNameEn : ""
      }`,
    },
  }));

export const applicationItemDecoder = succeed({})
  .assign("id", field("id", number))
  .assign("attributes", field("attributes", applicationAttributesDecoder))
  .map((report) => ({
    id: String(report.id),
    ...report.attributes,
  }));
