import { Guid, IUploadedFile, Request } from "./common";
import { ISecurityVisitsFull } from "./securityVisit";

export interface AreasForImprovment {
  id: string;
  title: string;
  description: string;
  files: Array<IUploadedFile>;
  executor: AreaForImprovmentExecutor,
  createdAt?: string;
  status?: AreaForImprovmentStatus;
  department?: string;
  reasonCancellation?: string;
  resultsWorkMessage?: string;
  resultsWorkFiles?: Array<IUploadedFile>;
}

export type AreasForImprovmentCompletionData = AreasForImprovment & { safetyVisit: Omit<ISecurityVisitsFull, "areasForImprovement"> };

export interface AreaForImprovmentExecutor {
  id: string;
  ru?: string; 
  en?: string;
}
  
export type AreasForImprovmentList = Array<AreasForImprovment>

export type IAreasForImprovmentPostDto = Request<{ body: { data: AreasForImprovmentList } }>;

export type AreasForImprovmentRequest = Request<{ body: { data: AreasForImprovmentList } }>;

export type AreasForImprovmentDetailRequest = Request<{ body: { data: Partial<AreasForImprovment> } }>;

export interface IGetAreaForImprovmentListFilters {
  startDate: string;
  endDate: string;
  executors: Guid[];
  applicationCreators: Guid[];
  status: AreaForImprovmentStatus | null;
}

export interface IGetAreaForImprovmentReportFilters {
  startDate: string;
  endDate: string;
  executors: Guid[];
  applicationCreators: Guid[];
  status: AreaForImprovmentStatus | null;
  departments: Guid[];
}

export enum AreaForImprovmentStatus {
  COMPLETE = "COMPLETE",
  IN_WORK = "IN_WORK",
  CANCELED = "CANCELED", 
  PASSED = "PASSED",
  NO_PASSED = "NO_PASSED"
}

export type AreasForImprovmentReportsItem = AreasForImprovmentCompletionData;

export interface ImprovmetReportsItemFormattedForExcel {
  id: string;
  title: string;
  description: string;
  createDate: string;
  executor?: string;
  status?: string;
  files?: string;
  securityVisitId?: string;
  completionPassedRadio?: string;
  resultsWorkMessage?: string;
  resultsWorkFiles?: string;
}
