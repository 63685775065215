import { action, makeObservable } from "mobx";

import { Pagination } from "shared/api/interfaces";

import { MutableEntity } from "../mutableEntity";

export class PaginationParams extends MutableEntity<Pagination> {
  constructor(config: Pagination) {
    super(config);
    makeObservable(this);
  }

  @action.bound
  public setPageIndex(pageIndex: number) {
    this.set("pageIndex", pageIndex);
  }

  @action.bound
  public setPageSize(pageSize: number) {
    this.set("pageSize", pageSize);
  }

  public nextPage = () => {
    this.setPageIndex(this.state.pageIndex + 1);
  };

  public prevPage = () => {
    if (this.state.pageIndex <= 0) this.resetPage();
    this.setPageIndex(this.state.pageIndex - 1);
  };

  public resetPage = () => {
    this.setPageIndex(1);
  };
}
