import { Guid, UniqueEntity } from "shared/api/interfaces";

import { MutableEntity } from "./mutableEntity";

/* eslint-disable */
/** Модель - это прослойкой для хранения данных и имплементации методов для работы с этими данными.
 * Правила работы с Model:
 *
 * 1. Доступ к апи обязан (shall) осуществляться из статичных методов класса. Это по сути минимальная прослойка
 *
 * 2. Сигнатура статичных методов должна быть (should) бинарной.
 * Первый аргумент - идентификатор сущности, с которой хотим взаимодействовать,
 * Второй аргумент - объект параметров для выполнения этой операции (дто, по сути).
 * Должны быть веские основания делать функцию с другой сигнатурой.
 *
 * 3. Нельзя (shall not) создавать модели с пустыми айдишниками.
 * Для этого есть связка MutableEntity<DTO> + DtoModel.
 * Данные хранятся в мутабельной сущности, статичные методы модели используются для взаимодействия с апи.
 *
 * 4. Не рекомендуется (should not) создавать модели напрямую.
 * Скорее всего, будет лучше вызвать Model.create() метод, который запросит данные с бекенда
 *
 * 5. Следует (should) создавать модели через асинхронный метод create.
 * Следует (should) создавать модели, которые реализуют этот метод.
 * Если метод существует, он обязан (shall) быть статичным, возвращать экземляр модели, и ему следует быть (should) бинарным.
 *
 * 6. Нельзя (shall not) использовать глобальные (создающиеся прямо в файлах) модели.
 * Любая модель обязана (shall) существовать в рамках компонента, и для сохранения состояния ее следует (should) заворачивать в useMemo.
 * Допускается (can) передавать модели по контексту.
 *
 * 7. Верхнеуровневым компонентом является <Root />, однако нельзя (shall not) хранить там любые модели.
 * Допускается (can) хранить на верхнем уровне модели данных, которые являются глобальными, например текущего пользователя.
 *
 *
 * @example пример реализации класса модели
 * class TaskModel extends Model<ITaskGetDto> {
 *   private static api: TaskApi = Container.get(TaskApi);
 *
 *   public static async create(schema: ITaskPostDto): Promise<TaskModel> {
 *     const id = await api.create(schema);
 *     return new TodoModel(id, schema);
 *   }
 *
 *   public static async get(filter: IGetTaskRequestFilter): Promise<TodoModel[]> {
 *     return await api.find(filter).map(TodoModel.constructor);
 *   }
 *
 *   public static async update(id: Guid, schema: Task): Promise<TodoModel> {
 *     return new TodoModel(await api.update(id, schema));
 *   }
 *
 *   public static async remove(id: Guid): Promise<void> {
 *     await api.remove(id);
 *   }
 *
 *   public async save(): Promise<TodoModel> {
 *     return await TodoModel.update(this.id, this.snapshot);
 *   }
 *
 *   public async remove() {
 *     return TodoModel.remove(this.id);
 *   }
 * }
 *
 * @example пример использования модели в коде
 * const task = await TaskModel.create({
 *   title: 'Example task',
 *   completed: false,
 * })
 *
 * task.mergeWith({
 *   title: 'new title',
 *   completed: true
 * })
 *
 * await task
 *  .set('title', 'another title set')
 *  .set('completed', false)
 *  .over('completed', (completed) => !completed)
 *  .save();
 *
 *  const tasks = await TaskModel.get({ completed: true });
 *
 *  await Promise.all(tasks.map((model) => model.toggle('completed').save()))
 * **/
/* eslint-enable */

export abstract class Model<TYPE extends object> extends MutableEntity<TYPE> implements UniqueEntity {
  protected constructor(id: Guid, payload: TYPE) {
    if (!id.trim()) throw new Error("id can not be an empty string");
    super(payload);
    this.id = id;
  }

  readonly id: Guid;
}
