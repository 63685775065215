import { Guid, Request } from "./common";

export enum AppLanguage {
  en = "en",
  ru = "ru",
}

export enum UserRoles {
  WORKER = "Worker",
  MANAGER = "Manager",
  LEADER = "Leader",
  ADMINISTRATOR = "Administrator",
}

export interface IViewer extends IUserGetDto {
  fullName: string;
  initials: string;
}
export interface IUserGetDto {
  id: Guid;
  firstName: string;
  lastName: string;
  patronymic: string | null;
  position: string;
  department: {
    id: number;
    name: string;
  };
  availableDepartments: { id: number, name: string }[];
  phone: string;
  email: string;
  role: string | null;
  interfaceLanguage: AppLanguage;
  avatarUrl: string | null;
}

type WithLocales<T> = Record<AppLanguage, T>;

export interface IUserEditableInfoGetDto {
  id: Guid;
  firstName: WithLocales<string>;
  lastName: WithLocales<string>;
  patronymic: WithLocales<string | null>;
  position: WithLocales<string>;
  phone: string;
  email: string;
  interfaceLanguage: AppLanguage;
  avatarUrl: string | null;
}

export interface INewEmailPost {
  verificationCode: string;
  newEmail: string;
}

export interface INewEmailConfirmationPost {
  verificationCode: string;
}

export interface INewEmailPostDto {
  confirmationToken: string;
  email: string;
}

export interface INewEmailConfirmationPostDto {
  confirmationToken: string;
}

export type SendCodeToNewMailRequest = Request<{ body: INewEmailPostDto }>;
export type ConfirmEmailRequest = Request<{ body: INewEmailConfirmationPostDto }>;

export type UploadRequest = Request<{ body: FormData }>;

export interface IUserUpdatePatchDto {
  firstNameEn?: string;
  firstNameRu?: string;
  lastNameEn?: string;
  lastNameRu?: string;
  middleNameEn?: string | null;
  middleNameRu?: string | null;
  positionEn?: string;
  positionRu?: string;
  phone?: string;
  Language?: string;
  avatar?: number | null;
}

export type UpdateUserRequest = Request<{ body: IUserUpdatePatchDto }>;
