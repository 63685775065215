import { action, computed, makeObservable, observable } from "mobx";

import type { ITableDto, Pagination, Sorting, UniqueEntity } from "shared/api/interfaces";

import { FilterParams, PaginationParams, SortingParams } from "./additionalRequestParams";

export abstract class TableDataModel<
  T extends UniqueEntity,
  F extends object = Record<string, never>,
  S extends string = never,
> {
  protected abstract getData(additionalQueryParams: Pagination & F & Sorting<S>): Promise<ITableDto<T>>;
  abstract readonly pagination: PaginationParams;
  abstract readonly filter: FilterParams<F>;
  abstract readonly sorting: SortingParams<S>;

  @observable
  public state: ITableDto<T> = { rows: [], totalCount: 0 };

  protected constructor() {
    makeObservable(this);
  }

  @computed
  public get rows() {
    return this.state.rows;
  }

  @computed
  public get count() {
    return this.state.totalCount;
  }

  @action.bound
  public async load(): Promise<void> {
    this.state = await this.getData(this.queryParams);
  }

  @action.bound
  public async loadAndOverwrite(): Promise<void> {
    this.pagination.resetPage();
    this.state = await this.getData(this.queryParams);
  }
  @action.bound
  public async loadNextPage(): Promise<void> {
    if (this.isEndReached) return;

    this.pagination.nextPage();

    const table = await this.getData(this.queryParams);
    this.mergeData(table);
  }

  public get queryParams(): Pagination & F & Sorting<S> {
    return {
      ...this.pagination.snapshot,
      ...this.filterAndSortingParams,
    };
  }

  public get filterAndSortingParams(): F & Sorting<S> {
    return {
      ...this.filter.snapshot,
      ...this.sorting.snapshot,
    };
  }

  @action
  public clearState: () => void = () => {
    this.state = { rows: [], totalCount: 0 };
  };

  @action
  private mergeData({ rows, totalCount }: ITableDto<T>) {
    this.state = { rows: [...this.state.rows, ...rows], totalCount };
  }

  @computed
  public get isEndReached(): boolean {
    return this.state.totalCount <= this.state.rows.length;
  }
}
