import { array, field, number, string, succeed } from "jsonous";

export const pollChecklistTemplate = succeed({})
  .assign("id", field("id", number))
  .assign("nameRu", field("nameRu", string))
  .assign("nameEn", field("nameEn", string))
  .assign(
    "checkpoints",
    field(
      "checkpoints",
      array(succeed({}).assign("ru", field("nameRu", string)).assign("en", field("nameEn", string))),
    ),
  )
  .map((template) => ({
    id: String(template.id),
    checkpoints: template.checkpoints,
    name: {
      en: template.nameEn,
      ru: template.nameRu,
    },
  }));
