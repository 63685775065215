import { Inject, Service } from "typedi";

import { emptyDecoder, fullUserInfoDecoder, jwtDecoder, localeDecoder, userDecoder } from "../decoders";
import {
  AppLanguage,
  INewEmailConfirmationPost,
  INewEmailPost,
  IUserEditableInfoGetDto,
  IUserGetDto,
  UpdateUserRequest,
} from "../interfaces";
import { METHODS, RequestManager } from "../requestManager";

@Service()
export class ViewerApi {
  @Inject()
  private requestManager!: RequestManager;

  public async getViewerDataRequest(): Promise<IUserGetDto> {
    const locale = await this.getUserLocaleRequest();
    return await this.requestManager.createRequest({
      url: "/api/users-permissions/getUserInfo",
      method: METHODS.GET,
      requestConfig: { acceptLanguage: locale },
      serverDataDecoder: userDecoder,
    })();
  }

  public async fullUserDataRequest(): Promise<IUserEditableInfoGetDto> {
    return await this.requestManager.createRequest({
      url: "/api/users-permissions/getUserFullInfo",
      method: METHODS.GET,
      requestConfig: {},
      serverDataDecoder: fullUserInfoDecoder,
    })();
  }

  public async updateUserRequest(body: UpdateUserRequest): Promise<null> {
    body.body.Language = body.body.Language === "ru" ? "Russian" : "English";
    return await this.requestManager.createRequest({
      url: `/api/users-permissions/updateUser`,
      method: METHODS.PATCH,
      requestConfig: {},
      serverDataDecoder: emptyDecoder,
    })(body);
  }

  public async sendEmailToOldMailRequest() {
    return await this.requestManager.createRequest({
      url: "/api/user/send-token-to-old-email",
      method: METHODS.POST,
      requestConfig: {},
      serverDataDecoder: emptyDecoder,
    })();
  }

  public async sendEmailToNewMailRequest(payload: INewEmailPost) {
    return await this.requestManager.createRequest({
      url: "/api/user/send-token-to-new-email",
      method: METHODS.POST,
      requestConfig: {},
      serverDataDecoder: emptyDecoder,
    })({ body: { confirmationToken: payload.verificationCode, email: payload.newEmail } });
  }

  public async confirmNewEmailRequest(payload: INewEmailConfirmationPost) {
    return await this.requestManager.createRequest({
      url: "/api/user/confirm-new-email",
      method: METHODS.POST,
      requestConfig: {},
      serverDataDecoder: jwtDecoder,
    })({ body: { confirmationToken: payload.verificationCode } });
  }

  private async getUserLocaleRequest(): Promise<AppLanguage> {
    return await this.requestManager.createRequest({
      url: "/api/users-permissions/getUserLocale",
      method: METHODS.GET,
      requestConfig: {},
      serverDataDecoder: localeDecoder,
    })();
  }
}
