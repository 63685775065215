import { useContext } from "react";
import Cookies from "js-cookie";
import { pipe } from "ramda";

import { api } from "shared/api/gateway";
import {
  AppLanguage,
  IEmailForPasswordResetPostDto,
  ILoginPostDto,
  INewEmailConfirmationPost,
  INewEmailPost,
  IResetPasswordPostDto,
  IUserEditableInfoGetDto,
  IUserGetDto,
  IUserUpdatePatchDto,
  IViewer,
} from "shared/api/interfaces";
import { AppContext } from "shared/config/context";
import { Model } from "shared/model";
import { withFullName, withInitials } from "shared/model/mixins";

export class ViewerModel extends Model<IViewer> {
  private static emptyViewer: IUserGetDto = {
    id: "no-viewer",
    firstName: "",
    lastName: "",
    patronymic: null,
    position: "",
    department: {
      id: 0,
      name: "",
    },
    availableDepartments: [],
    phone: "",
    role: null,
    email: "",
    interfaceLanguage: AppLanguage.ru,
    avatarUrl: null,
  };

  constructor(payload: IUserGetDto = ViewerModel.emptyViewer) {
    super(payload.id, pipe(withFullName, withInitials)(payload));
  }

  public getViewer = async () => {
    const user = await api.viewer.getViewerDataRequest();
    this.mergeWith(pipe(withFullName, withInitials)(user));
  };

  public login = async (payload: ILoginPostDto) => {
    const jwt = await api.auth.loginRequest({ body: payload });
    Cookies.set("token", `Bearer ${jwt}`, { expires: 365 });
    await this.getViewer();
  };

  public logout = () => {
    Cookies.remove("token");
    this.clear();
  };

  public async sendEmailForPasswordReset(body: IEmailForPasswordResetPostDto) {
    return api.auth.sendEmailForPasswordResetRequest({ body });
  }

  public resetPassword = async (request: IResetPasswordPostDto) => {
    await api.auth.resetPasswordRequest({ body: request });
    this.logout();
  };

  public get isViewerExist(): boolean {
    return Boolean(Cookies.get("token"));
  }
}

export function useViewerModel(): ViewerModel {
  return useContext(AppContext).viewerModel;
}

export class ViewerFullInfoModel extends Model<IUserEditableInfoGetDto> {
  constructor(payload: IUserEditableInfoGetDto) {
    super(payload.id, payload);
  }

  static uploadUserAvatar = async (payload: FormData) => {
    return await api.files.uploadFileRequest({ body: payload });
  };

  static getUserData = async () => {
    return await api.viewer.fullUserDataRequest();
  };

  static updateUserData = async (payload: IUserUpdatePatchDto) => {
    return await api.viewer.updateUserRequest({ body: payload });
  };

  static sendCodeToOldMail = async () => {
    return await api.viewer.sendEmailToOldMailRequest();
  };

  static sendCodeToNewMail = async (payload: INewEmailPost) => {
    return await api.viewer.sendEmailToNewMailRequest(payload);
  };

  static confirmNewMail = async (payload: INewEmailConfirmationPost) => {
    const jwt = await api.viewer.confirmNewEmailRequest(payload);
    Cookies.set("token", `Bearer ${jwt}`, { expires: 365 });
  };
}
