import React from "react";
import { createBrowserRouter } from "react-router-dom";
import { Container } from "typedi";

import Sidebar from "widgets/sidebar";

import MainRoleProtectedContent from "features/auth/mainRoleProtectedContent";
import ProtectedContent from "features/auth/protectedContent";
import RoleProtectedContent from "features/auth/roleProtectedContent";
import LanguageSwitcher from "features/languageSwitcher";

import { AppRoutes } from "shared/config/appRoutes";
import CompanyLogo from "shared/ui/companyLogo";
import { AuthLayout, MainLayout } from "shared/ui/pageLayout";

const appRoutes = Container.get(AppRoutes);

export const browserRouter = createBrowserRouter([
  {
    path: appRoutes.getMainUrl(),
    element: (
      <RoleProtectedContent>
        <ProtectedContent>
          <MainLayout sidebarContent={<Sidebar />} />
        </ProtectedContent>
      </RoleProtectedContent>
    ),
    children: [
      {
        index: true,
        element: <MainRoleProtectedContent />,
      },
      {
        path: appRoutes.getProfilePreviewUrl(),
        lazy: () => import("pages/profile/preview"),
      },
      {
        path: appRoutes.getProfileEditUrl(true),
        lazy: () => import("pages/profile/edit"),
      },
      {
        path: appRoutes.getEmailChangeUrl(true),
        lazy: () => import("pages/profile/emailChange"),
      },
      {
        path: appRoutes.getEmailChangeConfirmationUrl(true),
        lazy: () => import("pages/profile/emailChangeConfirmation"),
      },
      {
        path: appRoutes.getSecurityVisitMainUrl(),
        lazy: () => import("pages/securityVisits/main"),
      },
    ],
  },
  {
    path: appRoutes.getSecurityVisitMainUrl(),
    element: (
      <RoleProtectedContent>
        <ProtectedContent>
          <MainLayout sidebarContent={<Sidebar />} />
        </ProtectedContent>
      </RoleProtectedContent>
    ),
    children: [
      {
        index: true,
        lazy: () => import("pages/securityVisits/main"),
      },
      {
        path: appRoutes.getSecurityVisitListUrl(),
        lazy: () => import("pages/securityVisits/securityVisitsList"),
      },
      {
        path: appRoutes.getSecurityVisitCreationUrl(),
        lazy: () => import("pages/securityVisits/securityVisitsCreation"),
      },
      {
        path: appRoutes.getSecurityVisitEditUrl(),
        lazy: () => import("pages/securityVisits/securityVisitsCreation"),
      },
      {
        path: appRoutes.getSecurityVisitReportUrl(),
        lazy: () => import("pages/securityVisits/securityVisitsReport"),
      },
      {
        path: appRoutes.getSecurityCompletionBaseUrl(),
        children: [{ path: "*", lazy: () => import("processes/securityVisitsCompletion") }],
      },
    ],
  },
  {
    path: appRoutes.getAreasForImrovmentMainUrl(),
    element: (
      <RoleProtectedContent>
        <ProtectedContent>
          <MainLayout sidebarContent={<Sidebar />} />
        </ProtectedContent>
      </RoleProtectedContent>
    ),
    children: [
      {
        path: appRoutes.getSecurityVisitListUrl(),
        lazy: () => import("pages/areasForImprovment/areasForImprovmentList"),
      },
      {
        path: appRoutes.getSecurityVisitCreationUrl(),
        lazy: () => import("pages/securityVisits/securityVisitsCreation"),
      },
      {
        path: appRoutes.getAreasForImprovmentEditUrl(),
        lazy: () => import("pages/areasForImprovment/areasForImprovmentEdit"),
      },
      {
        path: appRoutes.getAreaForImprovmentReportUrl(),
        lazy: () => import("pages/areasForImprovment/areasForImprovmentReport"),
      },
      {
        path: appRoutes.getAreaForImprovmentCompletionBaseUrl(),
        lazy: () => import("pages/areasForImprovment/areasForImprovmentCompletion"),
      },
    ],
  },
  {
    path: appRoutes.getYaInspectorMainUrl(),
    element: (
      <RoleProtectedContent>
        <ProtectedContent>
          <MainLayout sidebarContent={<Sidebar />} />
        </ProtectedContent>
      </RoleProtectedContent>
    ),
    children: [
      {
        index: true,
        lazy: () => import("pages/yaInspector/main"),
      },
      {
        path: appRoutes.getYaInspectorApplicationMainUrl(),
        lazy: () => import("pages/yaInspector/applicationsPage"),
      },
      {
        path: appRoutes.getYaInspectorCreateApplicationUrl(),
        lazy: () => import("pages/yaInspector/applicationCreatePage"),
      },
      {
        path: appRoutes.getApplicationEditUrl(),
        lazy: () => import("pages/yaInspector/applicationEditPage"),
      },
      {
        path: appRoutes.getApplicationWatchUrl(),
        lazy: () => import("pages/yaInspector/applicationWatchPage"),
      },
      {
        path: appRoutes.getApplicationCompletionBaseUrl(true),
        children: [{ path: "*", lazy: () => import("processes/applicationCompletion") }],
      },
      {
        path: appRoutes.getYaInspectorReportUrl(),
        lazy: () => import("pages/yaInspector/yaInspectorReport"),
      },
      {
        path: appRoutes.getYaInspectorBestPracticesUrl(),
        lazy: () => import("pages/yaInspector/bestPractices"),
      },
      {
        path: appRoutes.getYaInspectorBestPracticesCreateUrl(),
        lazy: () => import("pages/yaInspector/bestPracticeCreate"),
      },
      {
        path: appRoutes.getYaInspectorBestPracticesEditUrl(),
        lazy: () => import("pages/yaInspector/bestPracticeEdit"),
      },
      {
        path: appRoutes.getYaInspectorBestPracticesWatchUrl(),
        lazy: () => import("pages/yaInspector/bestPracticeWatch"),
      },
      {
        path: appRoutes.getYaInspectorChecklistsUrl(),
        lazy: () => import("pages/yaInspector/checklists"),
      },
      {
        path: appRoutes.getChecklistCompletionUrl(),
        lazy: () => import("pages/yaInspector/checklistCompletion"),
      },
      {
        path: appRoutes.getYaInspectorChecklistWatchUrl(),
        lazy: () => import("pages/yaInspector/checklistWatch"),
      },
      {
        path: appRoutes.getChecklistEditUrl(),
        lazy: () => import("pages/yaInspector/checklistEdit"),
      },
    ],
  },
  {
    path: appRoutes.getAuthUrl(),
    element: (
      <AuthLayout
        logoContent={<CompanyLogo />}
        titleContent={process.env.REACT_APP_COMPANY_NAME}
        languageSelectorContent={<LanguageSwitcher />}
      />
    ),
    children: [
      {
        index: true,
        lazy: () => import("pages/auth/login"),
      },
      {
        path: appRoutes.getAuthPasswordResetUrl(),
        lazy: () => import("pages/auth/passwordReset"),
      },
      {
        path: appRoutes.getAuthPasswordResetConfirmationUrl(),
        lazy: () => import("pages/auth/passwordResetConfirmation"),
      },
      {
        path: appRoutes.getAuthPasswordUpdateUrl(),
        lazy: () => import("pages/auth/passwordUpdate"),
      },
    ],
  },
  {
    path: appRoutes.getForbiddenUrl(),
    element: <MainLayout sidebarContent={<Sidebar />} />,
    children: [
      {
        index: true,
        lazy: () => import("pages/forbidden"),
      },
    ],
  },
  {
    path: "*",
    element: <MainLayout sidebarContent={<Sidebar />} />,
    children: [
      {
        path: "*",
        lazy: () => import("pages/404"),
      },
    ],
  },
]);
