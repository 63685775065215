import { AppLanguage } from "shared/api";

interface Language {
  value: AppLanguage;
  label: string;
}

export const LANGUAGE_TO_LABEL: Record<AppLanguage, string> = {
  [AppLanguage.en]: "English",
  [AppLanguage.ru]: "Русский",
};

export const SUPPORTED_LANGUAGE_ITEMS: Language[] = [
  {
    value: AppLanguage.en,
    label: LANGUAGE_TO_LABEL[AppLanguage.en],
  },
  {
    value: AppLanguage.ru,
    label: LANGUAGE_TO_LABEL[AppLanguage.ru],
  },
];
