import { array, field, number, string, succeed } from "jsonous";

import { AppLanguage } from "../interfaces";

import { fieldOrDefaultDecoder } from "./utils";

export const localeDecoder = string.map((lang) => (lang === "Russian" ? AppLanguage.ru : AppLanguage.en));

export const userDecoder = succeed({})
  .assign(
    "id",
    field("id", number).map((id) => id.toString()),
  )
  .assign("firstName", field("firstName", string))
  .assign("lastName", field("lastName", string))
  .assign("patronymic", fieldOrDefaultDecoder("middleName", string, ""))
  .assign("position", fieldOrDefaultDecoder("position", string, ""))
  .assign(
    "department",
    field(
      "department",
      succeed({})
        .assign("name", fieldOrDefaultDecoder("name", string, null!))
        .assign("id", fieldOrDefaultDecoder("id", number, null!)),
    ),
  )
  .assign(
    "availableDepartments",
    field(
      "available_departments",
    array(
      succeed({})
        .assign("name", fieldOrDefaultDecoder("name", string, null!))
        .assign("id", fieldOrDefaultDecoder("id", number, null!)),
    )),
  )
  .assign("phone", field("phone", string))
  .assign("email", field("email", string))
  .assign("role", field("role", string))
  .assign(
    "interfaceLanguage",
    fieldOrDefaultDecoder("Language", string, "").map((lang) => (lang === "English" ? AppLanguage.en : AppLanguage.ru)),
  ) //enumDecoder(AppLanguage)
  .assign(
    "avatarUrl",
    fieldOrDefaultDecoder<string | null, null>(
      "avatar",
      field("url", string).map((url) => (url ? process.env.REACT_APP_SERVER_URL + url.slice(1) : null)),
      null,
    ),
  );

export const fullUserInfoDecoder = succeed({})
  .assign(
    "id",
    field("id", number).map((id) => id.toString()),
  )
  .assign(
    "firstName",
    succeed({})
      .assign(AppLanguage.ru, field("firstNameRu", string))
      .assign(AppLanguage.en, field("firstNameEn", string)),
  )
  .assign(
    "lastName",
    succeed({}).assign(AppLanguage.ru, field("lastNameRu", string)).assign(AppLanguage.en, field("lastNameEn", string)),
  )
  .assign(
    "patronymic",
    succeed({})
      .assign(AppLanguage.ru, fieldOrDefaultDecoder("middleNameRu", string, ""))
      .assign(AppLanguage.en, fieldOrDefaultDecoder("middleNameEn", string, "")),
  )
  .assign(
    "position",
    succeed({})
      .assign(AppLanguage.ru, fieldOrDefaultDecoder("positionRu", string, ""))
      .assign(AppLanguage.en, fieldOrDefaultDecoder("positionEn", string, "")),
  )
  .assign("phone", field("phone", string))
  .assign("email", field("email", string))
  .assign(
    "interfaceLanguage",
    fieldOrDefaultDecoder("Language", string, "").map((lang) => (lang === "English" ? AppLanguage.en : AppLanguage.ru)),
  )
  .assign(
    "avatarUrl",
    fieldOrDefaultDecoder(
      "avatar",
      field("url", string).map((url) => process.env.REACT_APP_SERVER_URL + url.slice(1)),
      "",
    ),
  );

export const userFileDecoder = array(field("id", number)).map((idArr) => idArr[0]);

export const emptyDecoder = succeed(null);
