import { Container, Inject, Service } from "typedi";

import { ApplicationApi } from "./application";
import { ApplicationCompletionApi } from "./applicationCompletion";
import { ApplicationsListApi } from "./applicationsList";
import { AreasForImprovmentApi } from "./areasForImprovment";
import { AuthApi } from "./auth";
import { BestPracticesApi } from "./bestPractices";
import { ChecklistTemplateApi } from "./checklistTemplate";
import { FilesApi } from "./files";
import { GlobalApi } from "./global";
import { SecurityVisitApi } from "./securityVisit";
import { SecurityVisitsApi } from "./securityVisits";
import { SecurityVisitsListApi } from "./securityVisitsList";
import { SecurityVisitsReportApi } from "./securityVisitsReport";
import { ViewerApi } from "./viewer";
import { YaInspectorCheckListApi } from "./yaInspectorCheckList";
import { YaInspectorCheckListTemplateApi } from "./yaInspectorChecklistTemplate";
import { YaInspectorApi } from "./yaInspectorMain";

export * from "./auth";

@Service()
class Api {
  @Inject()
  auth!: AuthApi;

  @Inject()
  viewer!: ViewerApi;

  @Inject()
  files!: FilesApi;

  @Inject()
  global!: GlobalApi;

  @Inject()
  securityVisits!: SecurityVisitsApi;

  @Inject()
  securityVisit!: SecurityVisitApi;

  @Inject()
  securityVisitsReport!: SecurityVisitsReportApi;

  @Inject()
  securityVisitsList!: SecurityVisitsListApi;

  @Inject()
  areasForImprovment!: AreasForImprovmentApi;

  @Inject()
  checklistTemplateApi!: ChecklistTemplateApi;

  @Inject()
  yaInspectorApi!: YaInspectorApi;

  @Inject()
  applicationsList!: ApplicationsListApi;

  @Inject()
  application!: ApplicationApi;

  @Inject()
  applicationCompletion!: ApplicationCompletionApi;

  @Inject()
  bestPractice!: BestPracticesApi;

  @Inject()
  yaInspectorCheckList!: YaInspectorCheckListApi;

  @Inject()
  yaInspectorCheckListTemplateApi!: YaInspectorCheckListTemplateApi;
}

export const api = Container.get(Api);
