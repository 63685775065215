import React from "react";
import { Outlet } from "react-router";
import { Layout } from "antd";
import classNames from "classnames";

import { useAppBreakpoint } from "../../breakpoints";

import styles from "./index.module.scss";

interface MainLayoutConfigContextValue {
  sidebarCollapsed: boolean;
  sidebarCollapsible: boolean;
  toggleSidebar: () => void;
  hideSidebar: () => void;
}

export const MainLayoutConfigContext = React.createContext({} as MainLayoutConfigContextValue);

const MainLayoutConfigProvider = React.memo(({ children }: React.PropsWithChildren) => {
  const { md } = useAppBreakpoint();
  const sidebarCollapsible = React.useMemo(() => !md, [md]);
  const [sidebarCollapsed, setSidebarCollapsed] = React.useState(() => sidebarCollapsible);

  React.useEffect(() => {
    setSidebarCollapsed(sidebarCollapsible);
  }, [sidebarCollapsible]);

  const toggleSidebar = React.useCallback(() => setSidebarCollapsed((sidebarCollapsed) => !sidebarCollapsed), []);
  const hideSidebar = React.useCallback(() => setSidebarCollapsed(true), []);

  return (
    <MainLayoutConfigContext.Provider
      value={{
        sidebarCollapsed,
        sidebarCollapsible,
        toggleSidebar,
        hideSidebar,
      }}
    >
      {children}
    </MainLayoutConfigContext.Provider>
  );
});

interface MainLayoutProps {
  sidebarContent: React.ReactNode;
}

export default React.memo(function MainLayout({ sidebarContent }: MainLayoutProps) {
  useAppBreakpoint();

  return (
    <MainLayoutConfigProvider>
      <Layout className={styles.layoutContainer}>
        {sidebarContent}
        <Outlet />
      </Layout>
    </MainLayoutConfigProvider>
  );
});

interface MainLayoutContentProps {
  headerContent?: React.ReactNode;
}

export const MainLayoutContent = React.memo(function ({
  headerContent,
  children,
}: React.PropsWithChildren<MainLayoutContentProps>) {
  const { sidebarCollapsed, sidebarCollapsible } = React.useContext(MainLayoutConfigContext);
  const { xs } = useAppBreakpoint();
  return (
    <Layout
      className={classNames(xs ? styles.pageContainerMobile : styles.pageContainer, {
        [styles.md]: !sidebarCollapsible,
        [styles.frozen]: sidebarCollapsible && !sidebarCollapsed,
      })}
    >
      {headerContent && (
        <Layout.Header className={classNames(styles.headerContainer, { [styles.md]: !sidebarCollapsible })}>
          {headerContent}
        </Layout.Header>
      )}{" "}
      <Layout.Content
        className={classNames(styles.contentContainer, {
          [styles.md]: !sidebarCollapsible,
        })}
      >
        {children}
      </Layout.Content>
    </Layout>
  );
});
