import { Inject, Service } from "typedi";

import { pollChecklistTemplate } from "../decoders";
import { IYaInspectorChecklistTemplate } from "../interfaces";
import { METHODS, RequestManager } from "../requestManager";

@Service()
export class YaInspectorCheckListTemplateApi {
  @Inject()
  private requestManager!: RequestManager;

  public async getActiveChecklistPoll(): Promise<IYaInspectorChecklistTemplate> {
    return await this.requestManager.createRequest({
      url: `/api/activePollChecklist`,
      method: METHODS.GET,
      serverDataDecoder: pollChecklistTemplate,
    })();
  }
}
