import { action, computed, makeObservable } from "mobx";

import { Sorting } from "shared/api/interfaces";

import { MutableEntity } from "../mutableEntity";

export class SortingParams<S extends string> extends MutableEntity<Sorting<S>> {
  constructor(
    config: Sorting<S> = {
      orderBy: undefined,
      isDescendingOrder: undefined,
    },
  ) {
    super(config);
    makeObservable(this);
  }

  @action.bound
  public setParams(orderBy = this.state.orderBy, isDescendingOrder = this.state.isDescendingOrder) {
    this.set("orderBy", orderBy);
    this.set("isDescendingOrder", isDescendingOrder);
  }

  @computed
  get enabled() {
    return Boolean(this.state.isDescendingOrder && this.state.orderBy);
  }

  @action.bound
  turnOff() {
    this.set("isDescendingOrder", undefined);
    this.set("orderBy", undefined);
  }

  turnOn(orderBy: S, isDescendingOrder: boolean) {
    this.setParams(orderBy, isDescendingOrder);
  }
}
