import React from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Avatar, AvatarProps, Card, Typography } from "antd";

import { useAppRoutes } from "shared/config/appRoutes";

import { useViewerModel } from "../model";

import styles from "./index.module.scss";

const ViewerCard = observer(() => {
  const viewerModel = useViewerModel();
  const appRoutes = useAppRoutes();

  return (
    <Link to={appRoutes.getProfilePreviewUrl()}>
      <Card type="inner">
        <Card.Meta
          className={styles.viewerDataContainer}
          avatar={<ViewerCardAvatar shape="square" />}
          title={
            <>
              <Typography.Text ellipsis>{viewerModel.state.firstName}</Typography.Text>
              <br />
              <Typography.Text ellipsis>{viewerModel.state.lastName}</Typography.Text>
            </>
          }
        />
      </Card>
    </Link>
  );
});

export const ViewerCardAvatar = observer(({ shape, size }: Pick<AvatarProps, "shape" | "size">) => {
  const viewerModel = useViewerModel();

  return (
    <Avatar size={size} shape={shape} src={viewerModel.state.avatarUrl}>
      {viewerModel.state.initials}
    </Avatar>
  );
});

export default React.memo(ViewerCard);
