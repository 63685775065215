import Decoder, { array, field, number, string, succeed } from "jsonous";
import { isNil } from "ramda";
import { err, ok } from "resulty";

export const identityValueDecoder = new Decoder(ok);

export function orDefaultDecoder<DECODER_VALUE, DEFAULT_VALUE>(
  decoder: Decoder<DECODER_VALUE>,
  defaultValue: DEFAULT_VALUE,
) {
  return new Decoder((value: Record<string, any>) =>
    decoder.decodeAny(value).cata({
      Ok: (value) => ok<string, DECODER_VALUE | DEFAULT_VALUE>(value),
      Err: () => ok<string, DEFAULT_VALUE>(defaultValue),
    }),
  );
}

export function fieldOrDefaultDecoder<A, D extends A | undefined>(
  key: string,
  decoder: Decoder<A>,
  defaultValue: D = undefined as D,
) {
  return new Decoder<D extends undefined ? undefined : A>((value: Record<string, any>) => {
    if (isNil(value[key])) return ok(defaultValue) as any;
    return orDefaultDecoder(field(key, decoder), defaultValue).decodeAny(value);
  });
}

export function toObject<T>(Class: { new (): T }): (obj: any) => Decoder<T> {
  // @ts-ignore
  return (obj) => succeed(Object.assign(new Class(), obj));
}

export function mergeRightDecoders<FIRST, SECOND>(firstDecoder: Decoder<FIRST>, secondDecoder: Decoder<SECOND>) {
  return new Decoder<FIRST & SECOND>((input) => {
    const first = firstDecoder.decodeAny(input);
    return first.andThen((firstValue) =>
      secondDecoder.map((secondValue) => ({ ...firstValue, ...secondValue })).decodeAny(input),
    );
  });
}

export function enumDecoder<VALUES>(matches: Record<string | number, VALUES>) {
  return new Decoder<VALUES>((input) => {
    if (matches[input]) return ok(matches[input]);
    return err(`enum decoder error.\nIncome ${JSON.stringify(input)};\nExpected: ${JSON.stringify(matches)}`);
  });
}

export function dataDecoder<T>(decoder: Decoder<T>) {
  return succeed({}).assign("data", field("data", decoder));
}

export function entityDecoder<T>(attributesDecoder: Decoder<T>) {
  const entityDataDecoder = succeed({})
    .assign("id", field("id", number))
    .assign("attributes", field("attributes", attributesDecoder));
  return succeed({})
    .assign("data", field("data", entityDataDecoder))
    .map((entity) => ({ id: String(entity.data.id), ...entity.data.attributes }));
}

export function entityArrayDecoder<T>(attributesDecoder: Decoder<T>) {
  const entityDataDecoder = succeed({})
    .assign("id", field("id", number))
    .assign("attributes", field("attributes", attributesDecoder));
  return succeed({})
    .assign("data", field("data", orDefaultDecoder(array(entityDataDecoder), [])))
    .map((entityArray) => entityArray.data.map((entity) => ({ id: String(entity.id), ...entity.attributes })));
}

export function tableEntityDecoder<T>(attributesDecoder: Decoder<T>) {
  return succeed({})
    .assign("id", field("id", number))
    .assign("attributes", field("attributes", attributesDecoder))
    .map((entity) => ({ id: String(entity.id), ...entity.attributes }));
}

export function attributesDecoder<T>(decoder: Decoder<T>) {
  return succeed({}).assign("attributes", field("attributes", decoder));
}

export const filesInEntitiesDecoder = dataDecoder(
  array(
    attributesDecoder(succeed({}).assign("name", field("name", string)).assign("url", field("url", string))).assign(
      "id",
      field("id", number),
    ),
  ),
).map((field) =>
  field.data.map((file) => ({
    id: file.id.toString(),
    name: file.attributes.name,
    url: process.env.REACT_APP_SERVER_URL + file.attributes.url.slice(1),
  })),
);
export const fileRequestDecoder = succeed({})
  .assign("name", field("name", string))
  .assign("url", field("url", string))
  .assign("id", field("id", number))
  .map(({ name, url, id }) => ({
    name,
    url: process.env.REACT_APP_SERVER_URL + url.slice(1),
    id: id.toString(),
  }));
export const filesRequestDecoder = array(fileRequestDecoder);
