import { boolean, field, number, string, succeed } from "jsonous";

import { ApplicationCompletionSteps, ApplicationStatus, ApplicationType, IUploadedFile } from "../interfaces";

import { commonUsersDecoder } from "./common";
import { attributesDecoder, dataDecoder, enumDecoder, fieldOrDefaultDecoder, filesInEntitiesDecoder } from "./utils";

const applicationResultDecoder = succeed({})
  .assign("isDangerResolved", fieldOrDefaultDecoder("isDangerResolved", boolean, false))
  .assign("isTheSignUp", fieldOrDefaultDecoder("isTheSignUp", boolean, false))
  .assign("areMeasuresHelped", fieldOrDefaultDecoder("areMeasuresHelped", boolean, false))
  .assign("appliedMeasures", fieldOrDefaultDecoder("appliedMeasures", string, ""))
  .assign("completedActions", fieldOrDefaultDecoder("completedActions", string, ""))
  .assign("resolvedDangerPhotos", fieldOrDefaultDecoder("resolvedDangerPhotos", filesInEntitiesDecoder, []));

export const applicationCompletionDecoder = dataDecoder(
  attributesDecoder(
    succeed({})
      .assign("type", field("type", enumDecoder(ApplicationType)))
      .assign("status", field("status", enumDecoder(ApplicationStatus)))
      .assign(
        "executors",
        field(
          "executors",
          commonUsersDecoder.map((executors) =>
            executors.map((executor) => ({
              ru: `${executor.lastNameRu} ${executor.firstNameRu} ${
                executor.middleNameRu ? executor.middleNameRu : ""
              }`,
              en: `${executor.lastNameEn} ${executor.firstNameEn} ${
                executor.middleNameEn ? executor.middleNameEn : ""
              }`,
            })),
          ),
        ),
      )
      .assign(
        "department",
        field(
          "department",
          dataDecoder(attributesDecoder(succeed({}).assign("name", field("name", string)))).map(
            (field) => field.data.attributes,
          ),
        ),
      )
      .assign(
        "riskLevel",
        field(
          "riskLevel",
          dataDecoder(
            attributesDecoder(succeed({}).assign("ru", field("nameRu", string)).assign("en", field("nameEn", string))),
          ).map((field) => field.data.attributes),
        ),
      )
      .assign(
        "riskDescription",
        field(
          "riskDescription",
          dataDecoder(
            attributesDecoder(succeed({}).assign("ru", field("nameRu", string)).assign("en", field("nameEn", string))),
          ).map((field) => field.data.attributes),
        ),
      )
      .assign("discrepancyDescription", field("discrepancyDescription", string))
      .assign("hazardLocation", field("hazardLocation", string))
      .assign("hazardDescription", field("hazardDescription", string))
      .assign("hazardPhotos", fieldOrDefaultDecoder("hazardPhotos", filesInEntitiesDecoder, []))
      .assign(
        "applicationResult",
        fieldOrDefaultDecoder("applicationResult", applicationResultDecoder, {
          isDangerResolved: false,
          isTheSignUp: false,
          areMeasuresHelped: false,
          completedActions: "",
          appliedMeasures: "",
          resolvedDangerPhotos: [] as IUploadedFile[],
        }),
      )
      .assign(
        "currentStep",
        fieldOrDefaultDecoder(
          "currentStep",
          enumDecoder(ApplicationCompletionSteps),
          ApplicationCompletionSteps.NEWDANGER,
        ),
      )
      .assign(
        "completedStep",
        fieldOrDefaultDecoder(
          "completedStep",
          enumDecoder(ApplicationCompletionSteps),
          ApplicationCompletionSteps.NEWDANGER,
        ),
      ),
  ).assign("id", field("id", number)),
).map((application) => ({
  staticData: {
    id: application.data.id.toString(),
    department: application.data.attributes.department.name,
    executors: application.data.attributes.executors,
    status: application.data.attributes.status,
    type: application.data.attributes.type,
    riskLevel: application.data.attributes.riskLevel,
    riskDescription: application.data.attributes.riskDescription,
    discrepancyDescription: application.data.attributes.discrepancyDescription,
    hazardLocation: application.data.attributes.hazardLocation,
    hazardDescription: application.data.attributes.hazardDescription,
    hazardPhotos: application.data.attributes.hazardPhotos,
  },
  dynamicData: application.data.attributes.applicationResult,
  stepData: {
    currentStep: application.data.attributes.currentStep,
    completedStep: application.data.attributes.completedStep,
  },
}));
