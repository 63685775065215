import { array, field, number, string, succeed } from "jsonous";

import { orDefaultDecoder } from "./utils";

export const managerOverviewDecoder = succeed({})
  .assign("departmentYearVisits", field("departmentYearVisits", number))
  .assign("departmentAllVisits", field("departmentAllVisits", number))
  .assign("managerVisits", field("managerVisits", number))
  .assign("managerVisitsStatisticsPercent", field("managerVisitsStatisticsPercent", string));

export const administratorOverviewDecoder = managerOverviewDecoder.assign(
  "allVisitsCount",
  field("allVisitsCount", number),
);

export const visitsWithStatusDecorator = succeed({})
  .assign(
    "data",
    field(
      "data",
      array(
        succeed({})
          .assign(
            "attributes",
            field(
              "attributes",
              succeed({})
                .assign(
                  "visitDate",
                  field("visitDate", string).map((date) => new Date(Date.parse(date))),
                )
                .assign("status", field("status", string))
                .assign(
                  "visitorFullName",
                  field(
                    "visitor",
                    succeed({}).assign(
                      "data",
                      field(
                        "data",
                        succeed({}).assign(
                          "attributes",
                          field(
                            "attributes",
                            succeed({})
                              .assign("firstNameRu", field("firstNameRu", string))
                              .assign("lastNameRu", field("lastNameRu", string))
                              .assign("middleNameRu", field("middleNameRu", orDefaultDecoder(string, "")))
                              .assign("firstNameEn", field("firstNameEn", string))
                              .assign("lastNameEn", field("lastNameEn", string))
                              .assign("middleNameEn", field("middleNameEn", orDefaultDecoder(string, "")))
                              .map((test) => ({
                                ru: test.middleNameRu
                                  ? [test.lastNameRu, test.firstNameRu, test.middleNameRu].join(" ")
                                  : [test.lastNameRu, test.firstNameRu].join(" "),
                                en: test.middleNameEn
                                  ? [test.lastNameEn, test.firstNameEn, test.middleNameEn].join(" ")
                                  : [test.lastNameEn, test.firstNameEn].join(" "),
                              })),
                          ),
                        ),
                      ),
                    ),
                  ),
                ),
            ),
          )
          .map((data) => ({
            date: data.attributes.visitDate,
            status: data.attributes.status,
            visitorFullName: data.attributes.visitorFullName.data.attributes,
          })),
      ),
    ),
  )
  .assign(
    "meta",
    field(
      "meta",
      succeed({}).assign(
        "pagination",
        field(
          "pagination",
          succeed({}).assign("page", field("page", number)).assign("pageCount", field("pageCount", number)),
        ),
      ),
    ),
  );

export const visitsWithDepartmentDecorator = succeed({})
  .assign(
    "data",
    field(
      "data",
      array(
        succeed({})
          .assign(
            "attributes",
            field(
              "attributes",
              succeed({})
                .assign(
                  "visitDate",
                  field("visitDate", string).map((date) => new Date(Date.parse(date))),
                )
                .assign(
                  "department",
                  field(
                    "department",
                    succeed({}).assign(
                      "data",
                      field(
                        "data",
                        succeed({}).assign(
                          "attributes",
                          field(
                            "attributes",
                            succeed({})
                              .assign("name", field("name", string))
                              .assign(
                                "client",
                                field(
                                  "client",
                                  succeed({}).assign(
                                    "data",
                                    field(
                                      "data",
                                      succeed({}).assign(
                                        "attributes",
                                        field("attributes", succeed({}).assign("name", field("name", string))),
                                      ),
                                    ),
                                  ),
                                ),
                              ),
                          ),
                        ),
                      ),
                    ),
                  ),
                ),
            ),
          )
          .map((data) => ({
            date: data.attributes.visitDate,
            clientName: data.attributes.department.data.attributes.client.data.attributes.name,
            departmentName: data.attributes.department.data.attributes.name,
          })),
      ),
    ),
  )
  .assign(
    "meta",
    field(
      "meta",
      succeed({}).assign(
        "pagination",
        field(
          "pagination",
          succeed({}).assign("page", field("page", number)).assign("pageCount", field("pageCount", number)),
        ),
      ),
    ),
  );

export const lastVisitsByDepartmentDecoder = array(
  succeed({})
    .assign(
      "visitDate",
      field("visitDate", string).map((date) => new Date(Date.parse(date))),
    )
    .assign(
      "department",
      field(
        "department",
        succeed({})
          .assign("name", field("name", string))
          .assign("client", field("client", succeed({}).assign("name", field("name", string)))),
      ),
    )
    .map((data) => ({
      date: data.visitDate,
      clientName: data.department.client.name,
      departmentName: data.department.name,
    })),
);
