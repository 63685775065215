import React from "react";
import { Outlet } from "react-router";
import { Layout, Row, Space, Typography } from "antd";
import classNames from "classnames";

import Paper from "shared/ui/paper";

import { useAppBreakpoint } from "../../breakpoints";

import styles from "./index.module.scss";

interface AuthLayoutProps {
  logoContent: React.ReactNode;
  titleContent: React.ReactNode;
  languageSelectorContent: React.ReactNode;
}

export default React.memo(function AuthLayout({ logoContent, titleContent, languageSelectorContent }: AuthLayoutProps) {
  const { sm } = useAppBreakpoint();
  return (
    <Layout className={styles.layoutContainer}>
      <Layout.Header className={styles.headerContainer}>
        <Space size={24}>
          <div className={styles.logoContainer}>{logoContent}</div>
          {sm && <Typography.Title style={{ color: "#647c80" }}>{titleContent}</Typography.Title>}
        </Space>
        <Row align="middle">{languageSelectorContent}</Row>
      </Layout.Header>
      <Layout.Content className={styles.contentContainer}>
        <div className={classNames(styles.contentWrapper, { [styles.sm]: sm })}>
          <Outlet />
        </div>
      </Layout.Content>
    </Layout>
  );
});

interface AuthFormLayoutProps {
  title: React.ReactNode;
}

export const AuthFormLayout = React.memo(({ title, children }: React.PropsWithChildren<AuthFormLayoutProps>) => {
  const { sm } = useAppBreakpoint();

  return (
    <Paper padding={sm ? "xl" : "lg"}>
      <Space direction="vertical" size="large" className={styles.formContainer}>
        <Typography.Title level={3}>{title}</Typography.Title>
        {children}
      </Space>
    </Paper>
  );
});
