import { string } from "jsonous";
import { Inject, Service } from "typedi";

import { jwtDecoder, resetPasswordDecoder } from "../decoders";
import { EmailForPasswordResetRequest, LoginRequest, ResetPasswordRequest } from "../interfaces";
import { METHODS, RequestManager } from "../requestManager";

@Service()
export class AuthApi {
  @Inject()
  private requestManager!: RequestManager;

  public async loginRequest(request: LoginRequest): Promise<string> {
    return await this.requestManager.createRequest({
      url: "/api/auth/local",
      method: METHODS.POST,
      serverDataDecoder: jwtDecoder,
    })(request);
  }

  public async sendEmailForPasswordResetRequest(request: EmailForPasswordResetRequest): Promise<string> {
    return await this.requestManager.createRequest({
      url: "/api/auth/forgot-password",
      method: METHODS.POST,
      serverDataDecoder: string,
    })(request);
  }

  public async resetPasswordRequest(request: ResetPasswordRequest) {
    return await this.requestManager.createRequest({
      url: "/api/auth/reset-password",
      method: METHODS.POST,
      serverDataDecoder: resetPasswordDecoder,
    })(request);
  }
}
