import React from "react";
import { Navigate, useLocation } from "react-router";

import { useViewerModel } from "entities/viewer";

import { useAppRoutes } from "shared/config/appRoutes";

function ProtectedContent({ children }: React.PropsWithChildren) {
  let location = useLocation();
  const appRoutes = useAppRoutes();
  const viewerModel = useViewerModel();

  if (viewerModel.isViewerExist) {
    return <>{children}</>;
  }

  return <Navigate to={appRoutes.getAuthUrl()} state={{ from: location }} replace />;
}

export default React.memo(ProtectedContent);
