import React from "react";
import { Link, useLocation } from "react-router-dom";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { Button, Col, Layout, Menu, Row, theme } from "antd";

import LogoutButton from "features/auth/logout";
import LanguageSwitcher from "features/languageSwitcher";

import ViewerCard, { ViewerCardAvatar } from "entities/viewer";

import { useAppRoutes } from "shared/config/appRoutes";
import { useAppBreakpoint } from "shared/ui/breakpoints";
import CompanyLogo from "shared/ui/companyLogo";
import { MainLayoutConfigContext } from "shared/ui/pageLayout";

import { useMenuItems } from "../model";

import styles from "./index.module.scss";

function Sidebar() {
  const location = useLocation();

  const { sidebarCollapsible, hideSidebar } = React.useContext(MainLayoutConfigContext);

  React.useEffect(hideSidebar, [location, hideSidebar]);

  return sidebarCollapsible ? <CollapsibleSidebar /> : <FixedSidebar />;
}

const FixedSidebar = React.memo(() => {
  const {
    token: { colorBgContainer, colorBorder },
  } = theme.useToken();

  const menuItems = useMenuItems();

  return (
    <Layout.Sider className={styles.sidebar} width={240} style={{ background: colorBgContainer }}>
      <div className={styles.sidebarContainer} style={{ borderRightColor: colorBorder }}>
        <Row className={styles.logoContainer} justify="center">
          <CompanyLogo />
        </Row>
        <Col className={styles.menuContainer}>
          <Menu className={styles.menuWrapper} mode="inline" items={menuItems} />
        </Col>
        <div className={styles.viewerPlateContainer}>
          <ViewerCard />
          <Row justify="space-between">
            <LanguageSwitcher />
            <LogoutButton />
          </Row>
        </div>
      </div>
    </Layout.Sider>
  );
});

const CollapsibleSidebar = React.memo(() => {
  const {
    token: { colorBgContainer, colorBorder },
  } = theme.useToken();
  const { xs } = useAppBreakpoint();
  const { sidebarCollapsed, hideSidebar } = React.useContext(MainLayoutConfigContext);

  return (
    <>
      <Layout.Sider
        className={xs && sidebarCollapsed ? styles.sidebarMobile : styles.sidebar}
        width={240}
        collapsible
        collapsed={sidebarCollapsed}
        collapsedWidth={40}
        trigger={null}
        style={{ background: colorBgContainer }}
      >
        <div className={styles.sidebarContainer} style={{ borderRightColor: colorBorder }}>
          {sidebarCollapsed ? <CollapsedSidebarContent /> : <OpenSidebarContent />}
        </div>
      </Layout.Sider>
      {!sidebarCollapsed && <div className={styles.darkBackground} onClick={hideSidebar} />}
    </>
  );
});

const CollapsedSidebarContent = React.memo(() => {
  const { sidebarCollapsed, toggleSidebar } = React.useContext(MainLayoutConfigContext);
  const appRoutes = useAppRoutes();
  const { xs } = useAppBreakpoint();

  return (
    <div className={xs ? styles.collapsibleSidebarContainerMobile : styles.collapsibleSidebarContainer}>
      <Row className={styles.collapsibleHeaderContainer} align="middle">
        <Button
          size="large"
          type="text"
          icon={sidebarCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          onClick={toggleSidebar}
        />
      </Row>
      <Row justify="center">
        <Link to={appRoutes.getProfilePreviewUrl()}>
          <ViewerCardAvatar shape="circle" size="small" />
        </Link>
      </Row>
    </div>
  );
});

const OpenSidebarContent = React.memo(() => {
  const { sidebarCollapsed, toggleSidebar } = React.useContext(MainLayoutConfigContext);

  const menuItems = useMenuItems();

  return (
    <>
      <Row className={styles.collapsibleHeaderContainer} justify="space-between" align="middle" wrap={false}>
        <div className={styles.collapsibleLogoContainer}>
          <CompanyLogo />
        </div>
        <Button
          type="text"
          size="large"
          icon={sidebarCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          onClick={toggleSidebar}
        />
      </Row>
      <Col className={styles.menuContainer}>
        <Menu className={styles.menuWrapper} mode="inline" items={menuItems} />
      </Col>
      <div className={styles.viewerPlateContainer}>
        <ViewerCard />
        <Row justify="space-between">
          <LanguageSwitcher />
          <LogoutButton />
        </Row>
      </div>
    </>
  );
});

export default React.memo(Sidebar);
