import { Guid, IUploadedFile, Request, UniqueEntity } from "./common";

export enum BestPracticesType {
  GOOD_PRACTICE = "GOOD_PRACTICE",
  GOOD_IDEA = "GOOD_IDEA",
}

export interface IBestPracticeTheme extends UniqueEntity {
  ru: string;
  en: string;
}

export interface IBestPractice extends UniqueEntity {
  type: BestPracticesType;
  name: string;
  theme: IBestPracticeTheme;
  executor: {
    id: string;
    ru: string;
    en: string;
  };
  createdAt: string;
  suggestor: {
    id: string;
    ru: string;
    en: string;
  };
  photos: IUploadedFile[];
}

export interface IGetBestPracticesListFilters {
  startDate: string;
  endDate: string;
  theme: Guid[];
  departments: Guid[];
  name: string;
  executors: Guid[];
  type: BestPracticesType | null;
}

export interface IBestPracticeCreate {
  type: BestPracticesType | null;
  name: string;
  theme: Guid | null;
  executor: Guid | null;
  suggestor: Guid | null;
  description: string;
  photos: IUploadedFile[];
}

export type IBestPracticePostDto = Request<{ body: { data: Partial<IBestPracticeCreate> } }>;

export interface IBestPracticeUpdate extends UniqueEntity {
  type: BestPracticesType | null;
  name: string;
  theme: IBestPracticeTheme | null;
  executor: {
    id: string;
    ru: string;
    en: string;
  } | null;
  description: string;
  photos: IUploadedFile[];
}

export type IBestPracticePutDto = Request<{ body: { data: Partial<IBestPracticeUpdate> } }>;

export interface IBestPracticeWatch {
  id: Guid;
  type: BestPracticesType | null;
  name: string;
  theme: IBestPracticeTheme | null;
  suggestor: {
    id: string;
    ru: string;
    en: string;
  } | null;
  executor: {
    id: string;
    ru: string;
    en: string;
  } | null;
  description: string;
  photos: IUploadedFile[];
}
