import { boolean, field, number, string, succeed } from "jsonous";

import { identityValueDecoder, orDefaultDecoder } from "./utils";

export const jwtDecoder = field("jwt", string);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const userFullInfoDecoder = succeed({})
  .assign("id", field("id", number))
  .assign("username", field("username", string))
  .assign("email", field("email", string))
  .assign("provider", field("provider", string))
  .assign("confirmed", field("confirmed", boolean))
  .assign("blocked", field("blocked", boolean))
  .assign("createdAt", field("createdAt", string))
  .assign("updatedAt", field("updatedAt", string))
  .assign("language", field("Language", string))
  .assign("department", field("department", string))
  .assign("firstNameRu", field("firstNameRu", string))
  .assign("firstNameEn", field("firstNameEn", string))
  .assign("lastNameRu", field("lastNameRu", string))
  .assign("lastNameEn", field("lastNameEn", string))
  .assign("middleNameRu", field("middleNameRu", orDefaultDecoder(string, null)))
  .assign("middleNameEn", field("middleNameEn", orDefaultDecoder(string, null)))
  .assign("phone", field("phone", string))
  .assign("positionRu", field("positionRu", string))
  .assign("positionEn", field("positionEn", string));

export const resetPasswordDecoder = succeed({})
  .assign("jwt", field("jwt", string))
  .assign("user", field("user", identityValueDecoder));
