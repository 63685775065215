import React from "react";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";

import { BreakpointContext } from "shared/ui/breakpoints";

export const withBreakpoints = (App: React.FC<React.PropsWithChildren>) => () => {
  const screens = useBreakpoint();

  return (
    <BreakpointContext.Provider value={screens}>
      <App />
    </BreakpointContext.Provider>
  );
};
