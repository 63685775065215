import React, { useCallback } from "react";
import { Navigate } from "react-router";
import { observer } from "mobx-react-lite";

import { useViewerModel } from "entities/viewer";

import { useAppRoutes } from "shared/config/appRoutes";
import { isAllowed } from "shared/lib";
import PageSpin from "shared/ui/pageSpin";

function RoleProtectedContent({ children }: React.PropsWithChildren) {
  const viewerModel = useViewerModel();
  const appRoutes = useAppRoutes();
  const getContent = useCallback(() => {
    return isAllowed(viewerModel.state.role!) ? (
      <Navigate to={appRoutes.getSecurityVisitMainUrl()} replace />
    ) : (
      <Navigate to={appRoutes.getProfilePreviewUrl()} replace />
    );
  }, [viewerModel.state.role]);

  return viewerModel.state.role ? getContent() : <PageSpin />;
}

export default observer(RoleProtectedContent);
