import React from "react";
import { observer } from "mobx-react-lite";

import { useViewerModel } from "entities/viewer";

import { UserRoles } from "shared/api";
import { useAppRoutes } from "shared/config/appRoutes";
import { redirectLeader } from "shared/lib/redirectLeader";
import { redirectManager } from "shared/lib/redirectManager";
import { redirectWorker } from "shared/lib/redirectWorker";

function RoleProtectedContent({ children }: React.PropsWithChildren) {
  const viewerModel = useViewerModel();
  const appRoutes = useAppRoutes();

  if (viewerModel.state.role! === UserRoles.LEADER) {
    return redirectLeader(window.location.pathname, appRoutes, children);
  }

  if (viewerModel.state.role! === UserRoles.WORKER) {
    return redirectWorker(window.location.pathname, appRoutes, children);
  }

  if (viewerModel.state.role! === UserRoles.MANAGER) {
    return redirectManager(window.location.pathname, appRoutes, children);
  }
  return <>{children}</>;
}

export default observer(RoleProtectedContent);
