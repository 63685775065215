import qs from "qs";
import { Inject, Service } from "typedi";

import { applicationItemDecoder, deleteSecurityReportDecoder, tableDataDecoder } from "../decoders";
import { IApplicationListItem, IApplicationsFilters, ITableDto, Pagination } from "../interfaces";
import { METHODS, RequestManager } from "../requestManager";

@Service()
export class ApplicationsListApi {
  @Inject()
  private requestManager!: RequestManager;

  public async getApplicationsListRequest({
    startDate,
    endDate,
    departments,
    applicationCreators,
    executors,
    type,
    riskLevel,
    riskDescription,
    pageIndex,
    pageSize,
    statuses,
  }: IApplicationsFilters & Pagination): Promise<ITableDto<IApplicationListItem>> {
    const query = qs.stringify(
      {
        sort: "createdAt:desc",
        filters: {
          status: {
            $in: statuses,
          },
          type: {
            $eq: type,
          },
          createdAt: {
            $gte: startDate,
            $lte: endDate,
          },
          department: {
            id: {
              $in: departments,
            },
            removed: {
              $eq: false,
            },
          },
          executors: {
            id: {
              $in: executors,
            },
          },
          applicationCreator: {
            id: {
              $in: applicationCreators,
            },
          },
          riskLevel: {
            id: {
              $in: riskLevel,
            },
          },
          riskDescription: {
            id: {
              $in: riskDescription,
            },
          },
        },
        populate: [
          "executors",
          "department",
          "department.client",
          "applicationCreator",
          "applicationResult",
          "riskLevel",
          "riskDescription",
          "applicationResult.resolvedDangerPhotos",
          "hazardPhotos",
        ],
        pagination: {
          pageSize,
          page: pageIndex,
        },
      },
      { skipNulls: true },
    );
    return await this.requestManager.createRequest({
      url: `/api/applications?${query}`,
      method: METHODS.GET,
      serverDataDecoder: tableDataDecoder(applicationItemDecoder),
    })();
  }

  public async deleteApplicationRequest(id: string) {
    return await this.requestManager.createRequest({
      url: `/api/applications/${id}`,
      method: METHODS.DELETE,
      requestConfig: {},
      serverDataDecoder: deleteSecurityReportDecoder,
    })();
  }
}
