import areasForImprovment from "./areasForImprovment.json";
import auth from "./auth.json";
import common from "./common.json";
import forbidden from "./forbidden.json";
import notFound from "./notFound.json";
import profile from "./profile.json";
import securityVisits from "./securityVisits.json";
import serverErrors from "./serverErrors.json";
import yaInspector from "./yaInspector.json";

export const translationsEn = {
  ...common,
  ...auth,
  ...profile,
  ...securityVisits,
  ...areasForImprovment,
  ...serverErrors,
  ...forbidden,
  ...notFound,
  ...yaInspector,
};
