import { field, number, string, succeed } from "jsonous";

import { SecurityVisitStatus } from "../interfaces";

import { enumDecoder, identityValueDecoder } from "./utils";

export const deleteSecurityReportDecoder = identityValueDecoder;

const clientAttributesDecoder = succeed({}).assign("name", field("name", string));

const clientDataDecoder = succeed({})
  .assign("id", field("id", number))
  .assign("attributes", field("attributes", clientAttributesDecoder));

const clientDecoder = succeed({}).assign("data", field("data", clientDataDecoder));

const departmentAttributesDecoder = succeed({})
  .assign("client", field("client", clientDecoder))
  .assign("name", field("name", string))
  .assign("code", field("code", string));

const departmentDataDecoder = succeed({})
  .assign("id", field("id", number))
  .assign("attributes", field("attributes", departmentAttributesDecoder));

export const departmentDecoder = succeed({}).assign("data", field("data", departmentDataDecoder));

const reportAttributesDecoder = succeed({})
  .assign("appealNumber", field("appealNumber", string))
  .assign("visitDate", field("visitDate", string))
  .assign("status", field("status", enumDecoder(SecurityVisitStatus)))
  .assign("department", field("department", departmentDecoder))
  .map((reportAttributes) => ({
    ...reportAttributes,
    department: { id: reportAttributes.department.data.id, ...reportAttributes.department.data.attributes },
  }));

export const securityVisitItemDecoder = succeed({})
  .assign("id", field("id", number))
  .assign("attributes", field("attributes", reportAttributesDecoder))
  .map((report) => ({
    id: String(report.id),
    ...report.attributes,
    client: { id: report.attributes.department.client.data.id, ...report.attributes.department.client.data.attributes },
  }));
