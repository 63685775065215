import { useContext } from "react";

import { api, Guid, IGlobalModel, UserRoles } from "shared/api";
import { AppContext } from "shared/config/context";
import { Model } from "shared/model";

export class GlobalModel extends Model<IGlobalModel> {
  private static emptyModel: IGlobalModel = {
    securityVisitYears: [],
    departments: [],
    clients: [],
    administrators: [],
    managers: [],
    leaders: [],
    workers: [],
    riskLevels: [],
    riskDescriptions: [],
    bestPracticesThemes: [],
    departmentTeams: [],
    applicationYears: [],
  };

  constructor(payload: IGlobalModel = GlobalModel.emptyModel) {
    super("1", payload);
  }

  private async getSecurityVisitsYears() {
    const years = await api.global.getSecurityVisitsYears();
    this.set("securityVisitYears", years);
  }

  private async getDepartments() {
    const departments = await api.global.getDepartments();
    this.set("departments", departments);
  }

  public getDepartmentByUserId(id: Guid) {
    const allUsers = [...this.view("leaders"), ...this.view("workers")];
    const targetDepartmentName = allUsers.find((user) => user.id === +id)!.department;
    return this.view("departments").find((department) => department.name === targetDepartmentName)!;
  }

  private async getClients() {
    const clients = await api.global.getAllClientsRequest();
    this.set("clients", clients);
  }

  private async geAdministrators() {
    const administrators = await api.global.getAllUserWithSpecificRole(UserRoles.ADMINISTRATOR);
    this.set("administrators", administrators);
  }

  private async getManagers() {
    const managers = await api.global.getAllUserWithSpecificRole(UserRoles.MANAGER);
    this.set("managers", managers);
  }

  private async getLeaders() {
    const leaders = await api.global.getAllUserWithSpecificRole(UserRoles.LEADER);
    this.set("leaders", leaders);
  }

  private async getWorkers() {
    const workers = await api.global.getAllUserWithSpecificRole(UserRoles.WORKER);
    this.set("workers", workers);
  }

  private async getRiskLevels() {
    const riskLevels = await api.global.getAllRiskLevels();
    this.set("riskLevels", riskLevels);
  }

  private async getRiskDescriptions() {
    const riskDescriptions = await api.global.getAllRiskDescriptions();
    this.set("riskDescriptions", riskDescriptions);
  }

  private async getAllBestPracticesThemes() {
    const bestPracticesThemes = await api.global.getAllBestPracticesThemes();
    this.set("bestPracticesThemes", bestPracticesThemes);
  }

  private async getALlDepartmentTeams() {
    const departmentTeams = await api.global.getAllDepartmentTeams();
    this.set("departmentTeams", departmentTeams);
  }

  private async getApplicationYears() {
    const years = await api.global.getApplicationYears();
    this.set("applicationYears", years);
  }

  public async getData() {
    await this.getSecurityVisitsYears();
    await this.getDepartments();
    await this.getClients();
    await this.geAdministrators();
    await this.getManagers();
    await this.getLeaders();
    await this.getWorkers();
    await this.getRiskLevels();
    await this.getRiskDescriptions();
    await this.getAllBestPracticesThemes();
    await this.getALlDepartmentTeams();
    await this.getApplicationYears();
  }
}

export function useGlobalModel() {
  return useContext(AppContext).global;
}
