import qs from "qs";
import { Inject, Service } from "typedi";

import {
  administratorOverviewDecoder,
  lastVisitsByDepartmentDecoder,
  managerOverviewDecoder,
  visitsWithDepartmentDecorator,
  visitsWithStatusDecorator,
} from "../decoders";
import { IGetFilteredMainSecurityVisits, IMainSecurityVisitsQuery } from "../interfaces";
import { METHODS, RequestManager } from "../requestManager";

@Service()
export class SecurityVisitsApi {
  @Inject()
  private requestManager!: RequestManager;

  public async getManagerOverviewData(data: IMainSecurityVisitsQuery) {
    return await this.requestManager.createRequest({
      url: `/api/manager-overview-statistics?visitDate=${data.basisYears}&department=${data.departments}`,
      method: METHODS.GET,
      requestConfig: {},
      serverDataDecoder: managerOverviewDecoder,
    })();
  }

  public async getAdministratorOverviewData(data: IMainSecurityVisitsQuery) {
    return await this.requestManager.createRequest({
      url: `/api/admin-overview-statistics?visitDate=${data.basisYears}&department=${data.departments}`,
      method: METHODS.GET,
      requestConfig: {},
      serverDataDecoder: administratorOverviewDecoder,
    })();
  }

  public async getVisitsInWork({ departments, basisYears, page }: IGetFilteredMainSecurityVisits) {
    const query = qs.stringify({
      filters: {
        visitDate: {
          $contains: basisYears,
        },
        department: {
          name: {
            $eq: departments,
          },
          removed: {
            $eq: false,
          },
          client: {
            removed: {
              $eq: false,
            },
          },
        },
        status: {
          $eq: "IN_WORK",
        },
      },
      populate: ["visitor"],
      sort: ["visitDate:desc"],
      pagination: {
        pageSize: 100,
        page: page,
      },
    });

    return await this.requestManager.createRequest({
      url: `/api/getSecurityVisitsList?${query}`,
      method: METHODS.GET,
      requestConfig: {},
      serverDataDecoder: visitsWithStatusDecorator,
    })();
  }

  public async completedVisits({ departments, basisYears, page }: IGetFilteredMainSecurityVisits) {
    const query = qs.stringify({
      filters: {
        visitDate: {
          $contains: basisYears,
        },
        department: {
          name: {
            $eq: departments,
          },
          removed: {
            $eq: false,
          },
          client: {
            removed: {
              $eq: false,
            },
          },
        },
        status: {
          $eq: "COMPLETE",
        },
      },
      populate: {
        department: {
          populate: ["client"],
        },
      },
      sort: ["visitDate:desc"],
      pagination: {
        pageSize: 100,
        page: page,
      },
    });

    return await this.requestManager.createRequest({
      url: `/api/getSecurityVisitsList?${query}`,
      method: METHODS.GET,
      requestConfig: {},
      serverDataDecoder: visitsWithDepartmentDecorator,
    })();
  }

  public async lastDepartmentsVisits() {
    return await this.requestManager.createRequest({
      url: "/api/last-visits",
      method: METHODS.GET,
      requestConfig: {},
      serverDataDecoder: lastVisitsByDepartmentDecoder,
    })();
  }
}
