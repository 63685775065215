import { array, field, number, string, succeed } from "jsonous";

import { departmentFullAttributesDecoder } from "./global";

const createSecurityVisitAttributesDecoder = succeed({}).assign("appealNumber", field("appealNumber", string));

const createSecurityVisitDataDecoder = succeed({}).assign(
  "attributes",
  field("attributes", createSecurityVisitAttributesDecoder),
);

export const createSecurityVisitDecoder = succeed({})
  .assign("data", field("data", createSecurityVisitDataDecoder))
  .map((securityVisit) => ({ ...securityVisit.data.attributes }));

const securityVisitDepartmentDataDecoder = succeed({})
  .assign("id", field("id", number))
  .assign("attributes", field("attributes", departmentFullAttributesDecoder))
  .map((securityVisitDepartmentData) => ({
    id: securityVisitDepartmentData.id,
    ...securityVisitDepartmentData.attributes,
  }));

const securityVisitDepartmentDecoder = succeed({})
  .assign("data", field("data", securityVisitDepartmentDataDecoder))
  .map((securityVisitDepartment) => ({ ...securityVisitDepartment.data }));

const visitorDateDecoder = succeed({}).assign("id", field("id", number));

const visitorDecoder = succeed({})
  .assign("data", field("data", visitorDateDecoder))
  .map((visitor) => ({ ...visitor.data }));

const employeeDataDecoder = succeed({})
  .assign("id", field("id", number))
  .map((employeeData) => employeeData.id);

const employeesDecoder = succeed({})
  .assign("data", field("data", array(employeeDataDecoder)))
  .map((employee) => [...employee.data]);

const securityVisitAttributeDecoder = succeed({})
  .assign("department", field("department", securityVisitDepartmentDecoder))
  .assign("visitDate", field("visitDate", string))
  .assign("status", field("status", string))
  .assign("createdAt", field("createdAt", string))
  .assign("visitor", field("visitor", visitorDecoder))
  .assign("employees", field("employees", employeesDecoder))
  .map((securityVisitAttributes) => ({
    ...securityVisitAttributes,
  }));

const securityVisitDataDecoder = succeed({})
  .assign("id", field("id", number))
  .assign("attributes", field("attributes", securityVisitAttributeDecoder))
  .map((securityVisitData) => ({
    id: securityVisitData.id,
    ...securityVisitData.attributes,
  }));

export const securityVisitDecoder = succeed({})
  .assign("data", field("data", securityVisitDataDecoder))
  .map((securityVisit) => ({ ...securityVisit.data }));

export const invitationsDecoder = array(string);
