import React from "react";

import { GlobalModel } from "entities/globalModal";
import { ViewerModel } from "entities/viewer";

import { AppContext, IAppContext } from "shared/config/context";

export const withAppContext = (App: React.FC<React.PropsWithChildren>) => () => {
  const context = React.useMemo<IAppContext>(
    () => ({
      viewerModel: new ViewerModel(),
      global: new GlobalModel(),
    }),
    [],
  );

  return (
    <AppContext.Provider value={context}>
      <App />
    </AppContext.Provider>
  );
};
