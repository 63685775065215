import Decoder, { array, boolean, field, number, string, succeed } from "jsonous";

import { entityArrayDecoder, entityDecoder, orDefaultDecoder } from "./utils";

export const tableDataDecoder = <ITEM>(rowItemDecoder: Decoder<ITEM>) =>
  succeed({})
    .assign("rows", field("data", array(rowItemDecoder)))
    .assign("totalCount", field("meta", field("pagination", field("total", number))));

export const adaptUser = (visitor: {
  attributes: { firstNameRu: string } & { firstNameEn: string } & { lastNameRu: string } & { lastNameEn: string } & {
    middleNameRu: string;
  } & { middleNameEn: string };
}) => ({
  fullName: {
    ru: [visitor.attributes.lastNameRu, visitor.attributes.firstNameRu, visitor.attributes.middleNameRu].join(" "),
    en: [visitor.attributes.lastNameEn, visitor.attributes.firstNameEn, visitor.attributes.middleNameEn].join(" "),
  },
});

export function userDataDecoder() {
  return succeed({})
    .assign("firstNameRu", field("firstNameRu", string))
    .assign("firstNameEn", field("firstNameEn", string))
    .assign("lastNameRu", field("lastNameRu", string))
    .assign("lastNameEn", field("lastNameEn", string))
    .assign("middleNameRu", field("middleNameRu", orDefaultDecoder(string, "")))
    .assign("middleNameEn", field("middleNameEn", orDefaultDecoder(string, "")));
}

const clientAttributesDecoder = succeed({})
  .assign("name", field("name", string))
  .assign("removed", field("removed", boolean));

const clientDecoder = entityDecoder(clientAttributesDecoder);

const departmentAttributesDecoder = succeed({})
  .assign("client", field("client", clientDecoder))
  .assign("name", field("name", string))
  .assign("code", field("code", string))
  .assign("removed", field("removed", boolean));

export const departmentCommonDecoder = entityDecoder(departmentAttributesDecoder);

export const photosAttributesDecoder = succeed({})
  .assign("name", field("name", string))
  .assign("url", field("url", string))
  .map((attributes) => ({ ...attributes, url: `${process.env.REACT_APP_SERVER_URL}${attributes.url.slice(1)}` }));

export const photosDecoder = entityArrayDecoder(photosAttributesDecoder);

const commonUserAttributesDecoder = succeed({})
  .assign("firstNameRu", field("firstNameRu", string))
  .assign("firstNameRu", field("firstNameRu", string))
  .assign("firstNameEn", field("firstNameEn", string))
  .assign("lastNameRu", field("lastNameRu", string))
  .assign("lastNameEn", field("lastNameEn", string))
  .assign("middleNameRu", field("middleNameRu", orDefaultDecoder(string, "")))
  .assign("middleNameEn", field("middleNameEn", orDefaultDecoder(string, "")));

export const commonUserDecoder = entityDecoder(commonUserAttributesDecoder);
export const commonUsersDecoder = entityArrayDecoder(commonUserAttributesDecoder);

const riskLevelAttributesDecoder = succeed({})
  .assign("ru", field("nameRu", string))
  .assign("en", field("nameEn", string));

export const riskLevelDecoder = entityDecoder(riskLevelAttributesDecoder);

const riskDescriptionAttributesDecoder = succeed({})
  .assign("ru", field("nameRu", string))
  .assign("en", field("nameEn", string));

export const riskDescriptionDecoder = entityDecoder(riskDescriptionAttributesDecoder);
