import { useMemo } from "react";
import { Container, Service } from "typedi";

@Service()
export class AppRoutes {
  public getMainUrl = () => "/";

  public getProfilePreviewUrl = () => "/profile";

  public getProfileEditUrl = (withPrefix: boolean = false) =>
    `${this.calculatePrefix(this.getProfilePreviewUrl(), withPrefix)}edit`;

  public getEmailChangeUrl = (withPrefix: boolean = false) =>
    `${this.calculatePrefix(this.getProfilePreviewUrl(), withPrefix)}email-change`;

  public getEmailChangeConfirmationUrl = (withPrefix: boolean = false) =>
    `${this.calculatePrefix(this.getProfilePreviewUrl(), withPrefix)}email-change-confirmation`;

  public getAuthUrl = () => "/auth";

  public getAuthPasswordResetUrl = (withPrefix: boolean = false) =>
    `${this.calculatePrefix(this.getAuthUrl(), withPrefix)}password-reset`;

  public getAuthPasswordResetConfirmationUrl = (withPrefix: boolean = false) =>
    `${this.calculatePrefix(this.getAuthUrl(), withPrefix)}password-reset-confirmation`;

  public getAuthPasswordUpdateUrl = (withPrefix: boolean = false) =>
    `${this.calculatePrefix(this.getAuthUrl(), withPrefix)}password-update`;

  public getSecurityVisitMainUrl = () => "/securityVisits";

  public getSecurityVisitListUrl = (withPrefix: boolean = false) =>
    `${this.calculatePrefix(this.getSecurityVisitMainUrl(), withPrefix)}list`;

  public getSecurityVisitReportUrl = (withPrefix: boolean = false) =>
    `${this.calculatePrefix(this.getSecurityVisitMainUrl(), withPrefix)}report`;

  public getSecurityVisitCreationUrl = (withPrefix: boolean = false) =>
    `${this.calculatePrefix(this.getSecurityVisitMainUrl(), withPrefix)}create`;

  public getSecurityVisitEditUrl = (withPrefix: boolean = false) =>
    `${this.calculatePrefix(this.getSecurityVisitMainUrl(), withPrefix)}edit/:id`;

  public getSecurityVisitEditWithIDUrl = (withPrefix: boolean = false, id?: string) =>
    `${this.calculatePrefix(this.getSecurityVisitMainUrl(), withPrefix)}edit/${id}`;

  public getSecurityCompletionBaseUrl = (withPrefix: boolean = false) =>
    `${this.calculatePrefix(this.getSecurityVisitMainUrl(), withPrefix)}completion`;

  public getSecurityVisitCompletionUrl = (withPrefix: boolean = false, ending?: number | string) =>
    `${this.calculatePrefix(this.getSecurityVisitMainUrl(), withPrefix)}completion/${ending}`;

  public getAreasForImrovmentMainUrl = () => "/areas-for-improvment";

  public getAreasForImrovmentListUrl = (withPrefix: boolean = false) =>
    `${this.calculatePrefix(this.getAreasForImrovmentMainUrl(), withPrefix)}list`;

  public getAreasForImprovmentEditUrl = (withPrefix: boolean = false) =>
    `${this.calculatePrefix(this.getAreasForImrovmentMainUrl(), withPrefix)}edit/:id`;

  public getAreasForImprovmentEditUrlWithId = (withPrefix: boolean = false, id: string) =>
    `${this.calculatePrefix(this.getAreasForImrovmentMainUrl(), withPrefix)}edit/${id}`;

  public getAreaForImprovmentCompletionBaseUrl = (withPrefix: boolean = false) =>
    `${this.calculatePrefix(this.getAreasForImrovmentMainUrl(), withPrefix)}completion/:id`;

  public getAreaForImprovmentCompletionUrl = (withPrefix: boolean = false, ending?: number | string) =>
    `${this.calculatePrefix(this.getAreasForImrovmentMainUrl(), withPrefix)}completion/${ending}`;

  public getAreaForImprovmentReportUrl = (withPrefix: boolean = false) =>
    `${this.calculatePrefix(this.getAreasForImrovmentMainUrl(), withPrefix)}report`;

  public getYaInspectorMainUrl = () => "/ya-inspector";

  public getYaInspectorApplicationMainUrl = (withPrefix: boolean = false) =>
    `${this.calculatePrefix(this.getYaInspectorMainUrl(), withPrefix)}applications`;

  public getApplicationCompletionBaseUrl = (withPrefix: boolean = false) =>
    `${this.calculatePrefix(this.getYaInspectorApplicationMainUrl(true), withPrefix)}completion`;

  public getApplicationCompletionUrl = (withPrefix: boolean = false, ending?: number | string) =>
    `${this.calculatePrefix(this.getYaInspectorApplicationMainUrl(true), withPrefix)}completion/${ending}`;

  public getYaInspectorReportUrl = (withPrefix: boolean = false) =>
    `${this.calculatePrefix(this.getYaInspectorMainUrl(), withPrefix)}report`;

  public getYaInspectorBestPracticesUrl = (withPrefix: boolean = false) =>
    `${this.calculatePrefix(this.getYaInspectorMainUrl(), withPrefix)}best-practices`;

  public getYaInspectorBestPracticesCreateUrl = (withPrefix: boolean = false) =>
    `${this.calculatePrefix(this.getYaInspectorMainUrl(), withPrefix)}createBestPractice`;

  public getYaInspectorBestPracticesEditUrl = (withPrefix: boolean = false) =>
    `${this.calculatePrefix(this.getYaInspectorMainUrl(), withPrefix)}editBestPractice/:id`;

  public getYaInspectorBestPracticesEditWithIDUrl = (withPrefix: boolean = false, id?: string) =>
    `${this.calculatePrefix(this.getYaInspectorMainUrl(), withPrefix)}editBestPractice/${id}`;

  public getYaInspectorBestPracticesWatchUrl = (withPrefix: boolean = false) =>
    `${this.calculatePrefix(this.getYaInspectorMainUrl(), withPrefix)}watchBestPractice/:id`;

  public getYaInspectorBestPracticesWatchWithIDUrl = (withPrefix: boolean = false, id?: string) =>
    `${this.calculatePrefix(this.getYaInspectorMainUrl(), withPrefix)}watchBestPractice/${id}`;

  public getYaInspectorChecklistsUrl = (withPrefix: boolean = false) =>
    `${this.calculatePrefix(this.getYaInspectorMainUrl(), withPrefix)}checklists`;

  public getChecklistCompletionUrl = (withPrefix: boolean = false) =>
    `${this.calculatePrefix(this.getYaInspectorMainUrl(), withPrefix)}completeChecklist`;

  public getChecklistEditUrl = (withPrefix: boolean = false) =>
    `${this.calculatePrefix(this.getYaInspectorMainUrl(), withPrefix)}editChecklist/:id`;
  public getChecklistEditWithIDUrl = (withPrefix: boolean = false, id?: string) =>
    `${this.calculatePrefix(this.getYaInspectorMainUrl(), withPrefix)}editChecklist/${id}`;

  public getYaInspectorChecklistWatchUrl = (withPrefix: boolean = false) =>
    `${this.calculatePrefix(this.getYaInspectorMainUrl(), withPrefix)}watchChecklist/:id`;

  public getYaInspectorChecklistsWatchWithIDUrl = (withPrefix: boolean = false, id?: string) =>
    `${this.calculatePrefix(this.getYaInspectorMainUrl(), withPrefix)}watchChecklist/${id}`;

  public getYaInspectorCreateApplicationUrl = (withPrefix: boolean = false) =>
    `${this.calculatePrefix(this.getYaInspectorMainUrl(), withPrefix)}createApplication`;

  public getApplicationEditUrl = (withPrefix: boolean = false) =>
    `${this.calculatePrefix(this.getYaInspectorMainUrl(), withPrefix)}editApplication/:id`;

  public getApplicationEditWithIDUrl = (withPrefix: boolean = false, id?: string) =>
    `${this.calculatePrefix(this.getYaInspectorMainUrl(), withPrefix)}editApplication/${id}`;

  public getApplicationWatchUrl = (withPrefix: boolean = false) =>
    `${this.calculatePrefix(this.getYaInspectorMainUrl(), withPrefix)}watchApplication/:id`;

  public getApplicationWatchWithIDUrl = (withPrefix: boolean = false, id?: string) =>
    `${this.calculatePrefix(this.getYaInspectorMainUrl(), withPrefix)}watchApplication/${id}`;

  public getForbiddenUrl = () => "/forbidden";

  private calculatePrefix = (prefix: string, withPrefix: boolean) => (withPrefix ? `${prefix}/` : "");
}

export function useAppRoutes() {
  return useMemo(() => Container.get(AppRoutes), []);
}
