import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { LogoutOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { BaseButtonProps } from "antd/es/button/button";
import { pipe } from "ramda";

import { useViewerModel } from "entities/viewer";

import { useAppServices } from "shared";
import { useAppRoutes } from "shared/config/appRoutes";

function LogoutButton({ size }: Pick<BaseButtonProps, "size">) {
  const appService = useAppServices();
  const viewerModel = useViewerModel();
  const navigate = useNavigate();
  const appRoutes = useAppRoutes();
  const { t } = useTranslation();
  return (
    <Button
      type="text"
      size={size}
      icon={<LogoutOutlined />}
      onClick={() =>
        appService.modal.confirm({
          content: t("forms.logout.content"),
          onOk: pipe(viewerModel.logout, () => navigate(appRoutes.getAuthUrl())),
        })
      }
    />
  );
}

export default React.memo(LogoutButton);
