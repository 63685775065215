import { initReactI18next } from "react-i18next";
import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { AppLanguage } from "shared/api";
import { translationsEn, translationsRu } from "shared/config/translations";

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: "ru",
    supportedLngs: ["en", "ru"],
    resources: {
      [AppLanguage.en]: { translation: translationsEn },
      [AppLanguage.ru]: { translation: translationsRu },
    },
    interpolation: {
      escapeValue: false,
    },
    parseMissingKeyHandler(key: string): any {
      return `%${key}%`;
    },
  });

export { i18next };
