import dayjs from "dayjs";
import { Inject, Service } from "typedi";

import { applicationCompletionDecoder, emptyDecoder } from "../decoders";
import {
  ApplicationCompletionSteps,
  ApplicationStatus,
  IApplicationCompletionData,
  IApplicationResultPutDto,
  ICompletedStepPutDto,
  ICurrentStepPutDto,
} from "../interfaces";
import { METHODS, RequestManager } from "../requestManager";

@Service()
export class ApplicationCompletionApi {
  @Inject()
  private requestManager!: RequestManager;

  public async getApplicationRequest(id: string): Promise<IApplicationCompletionData> {
    return await this.requestManager.createRequest({
      url: `/api/applications/${id}?populate=deep`,
      method: METHODS.GET,
      requestConfig: {},
      serverDataDecoder: applicationCompletionDecoder,
    })();
  }

  public putApplicationCurrentStep = async (
    id: string,
    payload: ICurrentStepPutDto<ApplicationCompletionSteps>,
  ): Promise<null> => {
    return await this.requestManager.createRequest({
      url: `/api/applications/${id}`,
      method: METHODS.PUT,
      requestConfig: {},
      serverDataDecoder: emptyDecoder,
    })(payload);
  };

  public putApplicationCompletedStep = async (
    id: string,
    payload: ICompletedStepPutDto<ApplicationCompletionSteps>,
  ): Promise<null> => {
    console.log(13);
    return await this.requestManager.createRequest({
      url: `/api/applications/${id}`,
      method: METHODS.PUT,
      requestConfig: {},
      serverDataDecoder: emptyDecoder,
    })(payload);
  };

  public async putApplicationCompletion(id: string, payload: IApplicationResultPutDto): Promise<null> {
    return await this.requestManager.createRequest({
      url: `/api/applications/${id}`,
      method: METHODS.PUT,
      requestConfig: {},
      serverDataDecoder: emptyDecoder,
    })(payload);
  }

  public async putApplicationCompletionComplete(id: string): Promise<null> {
    return await this.requestManager.createRequest({
      url: `/api/applications/${id}`,
      method: METHODS.PUT,
      requestConfig: {},
      serverDataDecoder: emptyDecoder,
    })({ body: { data: { status: ApplicationStatus.COMPLETE, completeDate: dayjs().toISOString() } } });
  }
}
