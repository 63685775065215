import qs from "qs";
import { Inject, Service } from "typedi";

import { bestPracticeDecoder, bestPracticesDecoder, identityValueDecoder, tableDataDecoder } from "../decoders";
import {
  IBestPractice,
  IBestPracticePostDto,
  IBestPracticePutDto,
  IGetBestPracticesListFilters,
  ITableDto,
  Pagination,
} from "../interfaces";
import { METHODS, RequestManager } from "../requestManager";

@Service()
export class BestPracticesApi {
  @Inject()
  private requestManager!: RequestManager;

  public async getBestPracticesListRequest({
    startDate,
    endDate,
    type,
    theme,
    departments,
    pageIndex,
    pageSize,
    executors,
    name,
  }: IGetBestPracticesListFilters & Pagination): Promise<ITableDto<IBestPractice>> {
    const query = qs.stringify(
      {
        sort: "createdAt:desc",
        filters: {
          type: {
            $eq: type,
          },
          theme: {
            id: {
              $in: theme,
            },
          },
          executor: {
            id: {
              $in: executors,
            },
            department: {
              id: {
                $in: departments,
              },
            },
          },
          createdAt: {
            $gte: startDate,
            $lte: endDate,
          },
          active: {
            $eq: true,
          },
          name: {
            $contains: name,
          },
        },
        populate: ["theme", "executor", "suggestor", "photos"],
        pagination: {
          pageSize,
          page: pageIndex,
        },
      },
      { skipNulls: true },
    );
    return await this.requestManager.createRequest({
      url: `/api/best-practices?${query}`,
      method: METHODS.GET,
      serverDataDecoder: tableDataDecoder(bestPracticesDecoder),
    })();
  }

  public async getBestPracticeRequest(id: any) {
    const query = qs.stringify({
      populate: ["executor", "theme", "photos", "suggestor"],
    });
    return await this.requestManager.createRequest({
      url: `/api/best-practices/${id}?${query}`,
      method: METHODS.GET,
      requestConfig: {},
      serverDataDecoder: bestPracticeDecoder,
    })();
  }

  public async createBestPracticeRequest(request: IBestPracticePostDto) {
    return await this.requestManager.createRequest({
      url: "/api/best-practices",
      method: METHODS.POST,
      requestConfig: {},
      serverDataDecoder: identityValueDecoder,
    })(request);
  }

  public async updateBestPracticeRequest(id: number, request: IBestPracticePutDto) {
    return await this.requestManager.createRequest({
      url: `/api/best-practices/${id}`,
      method: METHODS.PUT,
      requestConfig: {},
      serverDataDecoder: identityValueDecoder,
    })(request);
  }

  public async deleteBestPracticeRequest(id: string) {
    return await this.requestManager.createRequest({
      url: `/api/best-practices/${id}`,
      method: METHODS.DELETE,
      requestConfig: {},
      serverDataDecoder: identityValueDecoder,
    })();
  }
}
