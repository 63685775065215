import React from "react";
import { RouterProvider } from "react-router";

import PageSpin from "shared/ui/pageSpin";

import { browserRouter } from "./browserRouter";

export const withRouter = (App: React.FC<React.PropsWithChildren>) => () => {
  return (
    <App>
      <RouterProvider router={browserRouter} fallbackElement={<PageSpin />} />
    </App>
  );
};
