import React from "react";
import { Navigate } from "react-router";

import { AppRoutes } from "shared/config/appRoutes";

export function redirectLeader(path: string, routes: AppRoutes, children: React.ReactNode) {
  switch (path) {
    case routes.getSecurityVisitMainUrl():
      return <Navigate to={routes.getForbiddenUrl()} />;
    default:
      return <>{children}</>;
  }
}
