import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAsyncFn } from "react-use";
import Cookies from "js-cookie";

import "./initialize";
import { useGlobalModel } from "../entities/globalModal";
import { useViewerModel } from "../entities/viewer";
import PageSpin from "../shared/ui/pageSpin";

import { withProviders } from "./providers";

import "./index.scss";

import "dayjs/locale/ru";

function App({ children }: React.PropsWithChildren) {
  const viewerModel = useViewerModel();
  const globalModel = useGlobalModel();
  const { i18n } = useTranslation();

  const [state, getCommonData] = useAsyncFn(async () => {
    if (Cookies.get("token")) {
      await viewerModel.getViewer();
      await i18n.changeLanguage(viewerModel.state.interfaceLanguage);
      await globalModel.getData();
    }
  }, []);

  useEffect(() => {
    getCommonData();
  }, []);

  return state.loading ? <PageSpin /> : <>{children}</>;
}

export default React.memo(withProviders(App));
