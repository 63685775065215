import { array, field, number, string, succeed } from "jsonous";

import { fieldOrDefaultDecoder, filesInEntitiesDecoder, orDefaultDecoder } from "./utils";

const securityVisitReport = succeed({})
  .assign("safetyAndHealthActivities", field("safetyAndHealthActivities", string))
  .assign("attentionPoints", field("attentionPoints", string))
  .assign("bestPracticesText", field("bestPracticesText", string))
  .assign("bestPracticesFiles", fieldOrDefaultDecoder("bestPracticesFiles", filesInEntitiesDecoder, []));

const visitResultDecoder = succeed({}).assign("securityVisitReport", field("securityVisitReport", securityVisitReport));

const userAttributesDecoder = succeed({})
  .assign("firstNameRu", field("firstNameRu", string))
  .assign("lastNameRu", field("lastNameRu", string))
  .assign("middleNameRu", field("middleNameRu", orDefaultDecoder(string, null)))
  .assign("firstNameEn", field("firstNameEn", string))
  .assign("lastNameEn", field("lastNameEn", string))
  .assign("middleNameEn", field("middleNameEn", orDefaultDecoder(string, null)))
  .map((attributes) => ({
    ru: `${attributes.lastNameRu} ${attributes.firstNameRu} ${attributes.middleNameRu ? attributes.middleNameRu : ""}`,
    en: `${attributes.lastNameEn} ${attributes.firstNameEn} ${attributes.middleNameEn ? attributes.middleNameEn : ""}`,
  }));

const endUserDecoder = succeed({})
  .assign("id", field("id", number))
  .assign("attributes", field("attributes", userAttributesDecoder))
  .map((user) => ({ id: user.id, ...user.attributes }));

const endUsersDecoder = succeed({}).assign("data", field("data", array(endUserDecoder)));

const visitorDecoder = succeed({}).assign("data", field("data", endUserDecoder));

const reportAttributesDecoder = succeed({})
  .assign("appealNumber", field("appealNumber", string))
  .assign("visitDate", field("visitDate", string))
  .assign("visitResult", field("visitResult", visitResultDecoder))
  .assign("employees", field("employees", endUsersDecoder))
  .assign("visitor", field("visitor", visitorDecoder))
  .map(({ employees, visitor, visitResult, ...rest }) => ({
    safetyAndHealthActivities: visitResult.securityVisitReport.safetyAndHealthActivities,
    attentionPoints: visitResult.securityVisitReport.attentionPoints,
    bestPracticesText: visitResult.securityVisitReport.bestPracticesText,
    bestPracticesFiles: visitResult.securityVisitReport.bestPracticesFiles,
    employees: employees.data,
    visitor: visitor.data,
    ...rest,
  }));

export const reportDecoder = succeed({})
  .assign("id", field("id", number))
  .assign("attributes", field("attributes", reportAttributesDecoder))
  .map((report) => ({ id: report.id.toString(), ...report.attributes }));

export const firstVisitReportDateDecoder = orDefaultDecoder(string, null);
