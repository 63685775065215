import { array, boolean, field, number, string, succeed } from "jsonous";

import { adaptUser, userDataDecoder } from "./common";
import {
  attributesDecoder,
  dataDecoder,
  fieldOrDefaultDecoder,
  filesInEntitiesDecoder,
  orDefaultDecoder,
} from "./utils";

function checklistElementDecoder() {
  return succeed({})
    .assign("answerId", fieldOrDefaultDecoder("answerId", number, null!))
    .assign("questionId", field("questionId", number));
}

function checklistDecoder() {
  return succeed({})
    .assign("checklistId", field("checklistId", number))
    .assign(
      "checklist",
      field(
        "checklist",
        array(
          succeed({})
            .assign("mainQuestion", field("mainQuestion", checklistElementDecoder()))
            .assign("subQuestion", fieldOrDefaultDecoder("subQuestion", checklistElementDecoder(), null!)),
        ),
      ),
    );
}

export const fullSecurityVisitDecoder = dataDecoder(
  attributesDecoder(
    succeed({})
      .assign("appealNumber", field("appealNumber", string))
      .assign("visitDate", field("visitDate", string))
      .assign("createdAt", field("createdAt", string))
      .assign("currentStep", fieldOrDefaultDecoder("currentStep", string, null!))
      .assign("completedStep", fieldOrDefaultDecoder("completedStep", string, null!))
      .assign(
        "visitor",
        field(
          "visitor",
          dataDecoder(attributesDecoder(userDataDecoder()).map(adaptUser)).map((data) => ({ ...data.data })),
        ),
      )
      .assign(
        "employees",
        field(
          "employees",
          dataDecoder(array(attributesDecoder(userDataDecoder())).map((employees) => employees.map(adaptUser))).map(
            (data) => [...data.data],
          ),
        ),
      )
      .assign(
        "department",
        field(
          "department",
          dataDecoder(
            attributesDecoder(
              succeed({})
                .assign("name", field("name", string))
                .assign("code", field("code", string))
                .assign("removed", field("removed", boolean))
                .assign(
                  "client",
                  field(
                    "client",
                    dataDecoder(
                      attributesDecoder(succeed({}).assign("name", field("name", string))).assign(
                        "id",
                        field("id", number),
                      ),
                    ),
                  ),
                )
                .assign(
                  "address",
                  field(
                    "address",
                    succeed({})
                      .assign("city", field("city", string))
                      .assign("street", field("street", string))
                      .assign("buildingNumber", field("buildingNumber", string))
                      .assign("additionalInformation", field("additionalInformation", orDefaultDecoder(string, ""))),
                  ),
                ),
            ).assign("id", field("id", number)),
          ),
        ).map((department) => ({
          id: department.data.id,
          name: department.data.attributes.name,
          code: department.data.attributes.code,
          removed: department.data.attributes.removed,
          address: {
            ...department.data.attributes.address,
          },
          client: {
            id: department.data.attributes.client.data.id,
            name: department.data.attributes.client.data.attributes.name,
          },
        })),
      )
      .assign(
        "visitResult",
        fieldOrDefaultDecoder(
          "visitResult",
          succeed({})
            .assign(
              "rulesAndRegulationsChecklist",
              fieldOrDefaultDecoder("rulesAndRegulationsChecklist", checklistDecoder(), null!),
            )
            .assign("cultureChecklist", fieldOrDefaultDecoder("cultureChecklist", checklistDecoder(), null!))
            .assign(
              "securityVisitReport",
              fieldOrDefaultDecoder(
                "securityVisitReport",
                succeed({})
                  .assign("safetyAndHealthActivities", field("safetyAndHealthActivities", string))
                  .assign("attentionPoints", field("attentionPoints", string))
                  .assign("bestPracticesText", field("bestPracticesText", string))
                  .assign("bestPracticesFiles", fieldOrDefaultDecoder("bestPracticesFiles", filesInEntitiesDecoder, [])),
                {
                  safetyAndHealthActivities: "",
                  attentionPoints: "",
                  bestPracticesText: "",
                  bestPracticesFiles: [],
                },
              ),
            ),
          {
            rulesAndRegulationsChecklist: null!,
            cultureChecklist: null!,
            securityVisitReport: {
              safetyAndHealthActivities: "",
              attentionPoints: "",
              bestPracticesText: "",
              bestPracticesFiles: [],
            },
          },
        ),
      ),
  ).assign("id", field("id", number)),
);
