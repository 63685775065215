import { Guid, IUploadedFile, Request, UniqueEntity } from "./common";
import { IDepartment, IDepartmentFull, IRiskDescription, IRiskLevel } from "./globalModel";

export enum ApplicationStatus {
  COMPLETE = "COMPLETE",
  IN_WORK = "IN_WORK",
  DRAFT = "DRAFT",
}

export enum ApplicationType {
  HPE = "HPE",
  NEAR_MISS = "NEAR_MISS",
}

export interface IApplicationsFilters {
  startDate: string;
  endDate: string;
  departments: Guid[];
  executors: Guid[];
  applicationCreators: Guid[];
  statuses: ApplicationStatus[];
  type: ApplicationType | undefined;
  riskLevel: number[];
  riskDescription: number[];
}

export interface IApplicationResult {
  isDangerResolved: boolean;
  isTheSignUp: boolean;
  areMeasuresHelped: boolean;
  appliedMeasures: string;
  completedActions: string;
  resolvedDangerPhotos: IUploadedFile[];
}

export interface IApplicationCreate {
  department: IDepartment | null;
  executors: number[];
  type: ApplicationType | null;
  discrepancyDescription: string;
  applicationCreator: number | null;
  hazardLocation: string;
  status: ApplicationStatus | null;
  riskLevel: IRiskLevel | null;
  riskDescription: IRiskDescription | null;
  hazardDescription: string;
  hazardPhotos: IUploadedFile[];
}

export interface IApplicationEdit extends UniqueEntity {
  department: {
    id: number;
    name: string;
    removed: boolean;
    client: {
      id: number;
      name: string;
      removed: boolean;
    };
  } | null;
  executors: {
    id: string;
    ru: string;
    en: string;
  }[];
  type: ApplicationType | null;
  discrepancyDescription: string;
  hazardLocation: string;
  status: ApplicationStatus | null;
  riskLevel: IRiskLevel | null;
  riskDescription: IRiskDescription | null;
  hazardDescription: string;
  hazardPhotos: IUploadedFile[];
}

export interface IApplicationEditDto extends IApplicationEdit {
  applicationResult: IApplicationResult | null;
}

export type IApplicationCreatePostDto = Request<{ body: { data: Partial<IApplicationCreate> } }>;
export type IApplicationUpdatePutDto = Request<{ body: { data: Partial<IApplicationEditDto> } }>;

export interface IApplicationListItem extends UniqueEntity {
  createdAt: string;
  department: {
    id: string;
    name: string;
    code: string;
    removed: boolean;
    client: {
      id: string;
      name: string;
      removed: boolean;
    };
  };
  executors: {
    id: string;
    ru: string;
    en: string;
  }[];
  status: ApplicationStatus;
  type: ApplicationType;
  applicationNumber: string;
  discrepancyDescription: string;
  applicationCreator: {
    id: string;
    ru: string;
    en: string;
  };
  hazardLocation: string;
  applicationResult: IApplicationResult | null;
  riskLevel: IRiskLevel;
  riskDescription: IRiskDescription;
  inWorkDate: string | null;
  completeDate: string | null;
  hazardDescription: string;
  hazardPhotos: IUploadedFile[];
}

export interface IYaInspectorReportsItemFormattedForExcel {
  applicationNumber: string;
  type: string;
  department: string;
  client: string;
  inWorkDate: string;
  status: string;
  discrepancyDescription: string;
  discrepancyPhotos: string;
  applicationCreator: string;
  createAt: string;
  executors: string[];
  hazardLocation: string;
  appliedMeasures: string | undefined;
  isTheSignUp: string;
  riskDescription: string;
  riskLevel: string;
  completedActions: string | undefined;
  discrepancyResolvePhotos: string;
  completeDate: string;
}

export interface IApplicationWatchModel {
  type: ApplicationType | null;
  department: IDepartmentFull | null;
  riskLevel: IRiskLevel | null;
  riskDescription: IRiskDescription | null;
  executors: {
    ru: string;
    en: string;
  }[];
  discrepancyDescription: string;
  hazardLocation: string;
  hazardDescription: string;
  hazardPhotos: IUploadedFile[];
  applicationResult: IApplicationResult | null;
}
