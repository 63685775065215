import { AreasForImprovmentList } from "./areasForImprovment";
import { IUploadedFile, Request } from "./common";
import { IDepartmentFull } from "./globalModel";

export interface ISecurityVisitsFull {
  visitDate: string | null;
  appealNumber: string;
  visitor: ICompleteVisitEndUser | null;
  department: IDepartmentFull | null;
  employees: Array<ICompleteVisitEndUser> | null;
  createdAt: string;
  currentStep: string | null;
  completedStep: string | null;
  visitResult: {
    rulesAndRegulationsChecklist: IChecklistWithId | null;
    cultureChecklist: IChecklistWithId | null;
    securityVisitReport: {
      description?: string;
      safetyAndHealthActivities?: string;
      attentionPoints?: string;
      bestPracticesText: string;
      bestPracticesFiles: Array<IUploadedFile>;
    };
  };
  areasForImprovement: AreasForImprovmentList
}

interface ICompleteVisitEndUser {
  fullName: {
    ru: string;
    en: string;
  };
}
export interface IChecklistAnswer {
  answerId?: number;
  questionId: number;
}

export interface IChecklistElement {
  mainQuestion: IChecklistAnswer;
  subQuestion: IChecklistAnswer | null;
}

type IChecklistWithId = { checklistId: number; checklist: Array<IChecklistElement> };

export type ChecklistRequest = Request<{ body: { data: IChecklistWithId | null } }>;

export type CompletionStepRequest = Request<{ body: { data: { currentStep: string } | { completedStep: string } } }>;

export interface ITextWithFiles {
  id: number;
  text?: string;
  files?: Array<IUploadedFile>;
}

interface SecurityVisitReport {
  safetyAndHealthActivities?: string;
  attentionPoints?: string;
  bestPracticesText: string;
  bestPracticesFiles: Array<IUploadedFile>;
  areasForImprovement?: AreasForImprovmentList
}

export type SecurityVisitReportRequest = Request<{ body: { data: SecurityVisitReport } }>;

export enum SecurityVisitStatus {
  COMPLETE = "COMPLETE",
  IN_WORK = "IN_WORK",
}

export interface ISecurityVisitDetailModel {
  id: number | null;
  createdAt: string;
  visitDate: string | null;
  visitor: number | null;
  department: IDepartmentFull | null;
  employees: number[];
  status: string;
}

interface ISecurityVisit extends ISecurityVisitDetailModel {
  status: string;
}

export type ISecurityVisitPostDto = Request<{ body: { data: ISecurityVisit } }>;
export type ISecurityVisitSendInvitationsPostDto = Request<{
  body: { data: Pick<ISecurityVisitDetailModel, "visitDate" | "employees" | "visitor" | "department"> };
}>;
