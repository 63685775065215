import { field, string, succeed } from "jsonous";

import { BestPracticesType } from "../interfaces";

import { commonUserDecoder, photosDecoder } from "./common";
import { bestPracticeThemeDecoder } from "./global";
import { entityDecoder, enumDecoder, tableEntityDecoder } from "./utils";

const bestPracticeAttributesDecoder = succeed({})
  .assign("type", field("type", enumDecoder(BestPracticesType)))
  .assign("name", field("name", string))
  .assign("theme", field("theme", bestPracticeThemeDecoder))
  .assign("executor", field("executor", commonUserDecoder))
  .assign("suggestor", field("suggestor", commonUserDecoder))
  .assign("createdAt", field("createdAt", string))
  .assign("photos", field("photos", photosDecoder))
  .assign("description", field("description", string))
  .map((attributes) => ({
    ...attributes,
    executor: {
      id: String(attributes.executor.id),
      ru: `${attributes.executor.lastNameRu} ${attributes.executor.firstNameRu} ${
        attributes.executor.middleNameRu ? attributes.executor.middleNameRu : ""
      }`,
      en: `${attributes.executor.lastNameEn} ${attributes.executor.firstNameEn} ${
        attributes.executor.middleNameEn ? attributes.executor.middleNameEn : ""
      }`,
    },
    suggestor: {
      id: String(attributes.suggestor.id),
      ru: `${attributes.suggestor.lastNameRu} ${attributes.suggestor.firstNameRu} ${
        attributes.suggestor.middleNameRu ? attributes.suggestor.middleNameRu : ""
      }`,
      en: `${attributes.suggestor.lastNameEn} ${attributes.suggestor.firstNameEn} ${
        attributes.suggestor.middleNameEn ? attributes.suggestor.middleNameEn : ""
      }`,
    },
  }));

export const bestPracticesDecoder = tableEntityDecoder(bestPracticeAttributesDecoder);

export const bestPracticeDecoder = entityDecoder(bestPracticeAttributesDecoder);
