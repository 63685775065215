import qs from "qs";
import { Inject, Service } from "typedi";

import {
  bestPracticeThemesDefaultDecoder,
  clientsDecoder,
  departmentFullDecoder,
  departmentsDecoder,
  departmentTeamsDecoder,
  riskDescriptionsDecoder,
  riskLevelsDecoder,
  usersFilterDecoder,
  yearsDecoder,
} from "../decoders";
import { UserRoles } from "../interfaces";
import { METHODS, RequestManager } from "../requestManager";

@Service()
export class GlobalApi {
  @Inject()
  private requestManager!: RequestManager;

  public async getSecurityVisitsYears() {
    return await this.requestManager.createRequest({
      url: "/api/years",
      method: METHODS.GET,
      requestConfig: {},
      serverDataDecoder: yearsDecoder,
    })();
  }

  public async getDepartments() {
    return await this.requestManager.createRequest({
      url: "/api/all-departments",
      method: METHODS.GET,
      requestConfig: {},
      serverDataDecoder: departmentsDecoder,
    })();
  }

  public async getDepartment(id: number) {
    const query = qs.stringify({
      populate: ["address", "client"],
    });
    return await this.requestManager.createRequest({
      url: `/api/departments/${id}?${query}`,
      method: METHODS.GET,
      requestConfig: {},
      serverDataDecoder: departmentFullDecoder,
    })();
  }

  public async getAllClientsRequest() {
    return await this.requestManager.createRequest({
      url: "/api/all-clients",
      method: METHODS.GET,
      requestConfig: {},
      serverDataDecoder: clientsDecoder,
    })();
  }

  public async getAllUserWithSpecificRole(role: UserRoles) {
    const query = qs.stringify({
      populate: ["department", "department.address", "department.client"],
    });
    return await this.requestManager.createRequest({
      url: `/api/users?filters[role][name][$eq]=${role}&${query}`,
      method: METHODS.GET,
      requestConfig: {},
      serverDataDecoder: usersFilterDecoder,
    })();
  }

  public async getAllRiskLevels() {
    return await this.requestManager.createRequest({
      url: `/api/all-risk-levels`,
      method: METHODS.GET,
      requestConfig: {},
      serverDataDecoder: riskLevelsDecoder,
    })();
  }

  public async getAllRiskDescriptions() {
    return await this.requestManager.createRequest({
      url: `/api/all-risk-descriptions`,
      method: METHODS.GET,
      requestConfig: {},
      serverDataDecoder: riskDescriptionsDecoder,
    })();
  }

  public async getAllBestPracticesThemes() {
    return await this.requestManager.createRequest({
      url: `/api/all-best-practices-themes`,
      method: METHODS.GET,
      requestConfig: {},
      serverDataDecoder: bestPracticeThemesDefaultDecoder,
    })();
  }

  public async getAllDepartmentTeams() {
    const query = qs.stringify({
      populate: ["leader", "members", "department"],
    });
    return await this.requestManager.createRequest({
      url: `/api/department-teams?${query}`,
      method: METHODS.GET,
      requestConfig: {},
      serverDataDecoder: departmentTeamsDecoder,
    })();
  }

  public async getApplicationYears() {
    return await this.requestManager.createRequest({
      url: "/api/get-application-years",
      method: METHODS.GET,
      requestConfig: {},
      serverDataDecoder: yearsDecoder,
    })();
  }
}
