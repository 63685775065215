import { Inject, Service } from "typedi";

import { yaInspectorStatisticsDecoder } from "../decoders";
import { IMainYaInspectorStatisticsDTO } from "../interfaces";
import { METHODS, RequestManager } from "../requestManager";

@Service()
export class YaInspectorApi {
  @Inject()
  private requestManager!: RequestManager;

  public async getApplicationStatistics({
    year,
    department,
  }: {
    year: string;
    department: string;
  }): Promise<IMainYaInspectorStatisticsDTO> {
    return await this.requestManager.createRequest({
      url: `/api/get-application-statistics?year=${year}&department=${department}`,
      method: METHODS.GET,
      requestConfig: {},
      serverDataDecoder: yaInspectorStatisticsDecoder,
    })();
  }
}
