import qs from "qs";
import { Inject, Service } from "typedi";

import {
  createSecurityVisitDecoder,
  emptyDecoder,
  fullSecurityVisitDecoder,
  invitationsDecoder,
  securityVisitDecoder,
} from "../decoders";
import {
  ChecklistRequest,
  CompletionStepRequest,
  ISecurityVisitPostDto,
  ISecurityVisitSendInvitationsPostDto,
  SecurityVisitReportRequest,
} from "../interfaces";
import { METHODS, RequestManager } from "../requestManager";

@Service()
export class SecurityVisitApi {
  @Inject()
  private requestManager!: RequestManager;

  public async createSecurityVisitRequest(request: ISecurityVisitPostDto) {
    return await this.requestManager.createRequest({
      url: "/api/security-visits",
      method: METHODS.POST,
      requestConfig: {},
      serverDataDecoder: createSecurityVisitDecoder,
    })(request);
  }

  public async updateSecurityVisitRequest(id: number, request: ISecurityVisitPostDto) {
    return await this.requestManager.createRequest({
      url: `/api/security-visits/${id}`,
      method: METHODS.PUT,
      requestConfig: {},
      serverDataDecoder: createSecurityVisitDecoder,
    })(request);
  }

  public async getSecurityVisitRequest(id: any) {
    const query = qs.stringify({
      populate: ["department", "department.address", "department.client", "visitor", "employees"],
    });
    return await this.requestManager.createRequest({
      url: `/api/security-visits/${id}?${query}`,
      method: METHODS.GET,
      requestConfig: {},
      serverDataDecoder: securityVisitDecoder,
    })();
  }

  public async sendInvitationsRequest(request: ISecurityVisitSendInvitationsPostDto) {
    return await this.requestManager.createRequest({
      url: `/api/user/sendInvitation`,
      method: METHODS.POST,
      requestConfig: {},
      serverDataDecoder: invitationsDecoder,
    })(request);
  }

  public async getFullSecurityVisit(id: string) {
    return await this.requestManager.createRequest({
      url: `/api/security-visits/${id}?populate=deep`,
      method: METHODS.GET,
      requestConfig: {},
      serverDataDecoder: fullSecurityVisitDecoder,
    })();
  }

  public async putSecurityVisitReport(data: SecurityVisitReportRequest, id: number) {
    return await this.requestManager.createRequest({
      url: `/api/update-security-visit-report/${id}`,
      method: METHODS.PUT,
      requestConfig: {},
      serverDataDecoder: emptyDecoder,
    })(data);
  }

  public async putRulesChecklist(data: ChecklistRequest, id: number) {
    return await this.requestManager.createRequest({
      url: `/api/update-rules-checklist/${id}`,
      method: METHODS.PUT,
      requestConfig: {},
      serverDataDecoder: emptyDecoder,
    })(data);
  }

  public async putCultureChecklist(data: ChecklistRequest, id: number) {
    return await this.requestManager.createRequest({
      url: `/api/update-culture-checklist/${id}`,
      method: METHODS.PUT,
      requestConfig: {},
      serverDataDecoder: emptyDecoder,
    })(data);
  }

  public async putCompletionStep(data: CompletionStepRequest, id: number) {
    return await this.requestManager.createRequest({
      url: `/api/security-visits/${id}`,
      method: METHODS.PUT,
      requestConfig: {},
      serverDataDecoder: emptyDecoder,
    })(data);
  }

  public async putCompleteStatus(id: number) {
    return await this.requestManager.createRequest({
      url: `/api/security-visits/${id}`,
      method: METHODS.PUT,
      requestConfig: {},
      serverDataDecoder: emptyDecoder,
    })({ body: { data: { status: "COMPLETE" } } });
  }
}
