import qs from "qs";
import { Inject, Service } from "typedi";

import { createApplicationDataDecoder, fullApplicationDecoder } from "../decoders";
import { IApplicationCreatePostDto, IApplicationUpdatePutDto } from "../interfaces";
import { METHODS, RequestManager } from "../requestManager";

@Service()
export class ApplicationApi {
  @Inject()
  private requestManager!: RequestManager;

  public async createApplicationRequest(request: IApplicationCreatePostDto) {
    return await this.requestManager.createRequest({
      url: "/api/applications",
      method: METHODS.POST,
      requestConfig: {},
      serverDataDecoder: createApplicationDataDecoder,
    })(request);
  }

  public async updateApplicationRequest(id: number, request: IApplicationUpdatePutDto) {
    return await this.requestManager.createRequest({
      url: `/api/applications/${id}`,
      method: METHODS.PUT,
      requestConfig: {},
      serverDataDecoder: createApplicationDataDecoder,
    })(request);
  }

  public async getFullApplicationRequest(id: any) {
    const query = qs.stringify({
      populate: [
        "department",
        "department.client",
        "department.address",
        "riskLevel",
        "riskDescription",
        "executors",
        "hazardPhotos",
        "applicationResult",
        "applicationResult.resolvedDangerPhotos",
      ],
    });
    return await this.requestManager.createRequest({
      url: `/api/applications/${id}?${query}`,
      method: METHODS.GET,
      requestConfig: {},
      serverDataDecoder: fullApplicationDecoder,
    })();
  }
}
