import { action, computed, makeObservable, observable, toJS } from "mobx";
import { call, clone, difference, keys, without } from "ramda";

type Watcher<T, K> = (fieldName: K, newValue: T, oldValue: T) => void;

type WatchersMap<T extends object> = {
  [K in keyof T]: Watcher<T[K], K>[];
};

type TypedKeys<T, AllowedTypes> = { [K in keyof T]: T[K] extends AllowedTypes ? K : never }[keyof T];

export class MutableEntity<TYPE extends object> {
  @observable
  private _state: TYPE;

  @observable
  private readonly locks: Record<keyof TYPE, boolean>;

  private readonly watchers: WatchersMap<TYPE>;

  private globalWatchers: Watcher<TYPE[keyof TYPE], keyof TYPE>[];

  protected readonly initialState: TYPE;

  public constructor(initialValues: TYPE) {
    this._state = clone(initialValues);
    this.initialState = clone(initialValues);

    // @ts-ignore
    this.locks = Object.fromEntries(Object.keys(initialValues).map((key) => [key, false]));

    // @ts-ignore
    this.watchers = Object.fromEntries(Object.keys(initialValues).map((key) => [key, []]));

    this.globalWatchers = [];

    makeObservable(this);
  }

  public view<KEY extends keyof TYPE>(fieldName: KEY): TYPE[KEY] {
    return this._state[fieldName];
  }

  public keys(): (keyof TYPE)[] {
    return keys(this._state);
  }

  public keysWithout<T extends keyof TYPE>(keys: T[]): Exclude<keyof TYPE, T>[] {
    // @ts-ignore
    return without(keys, this.keys());
  }

  public entriesFor<KEY extends keyof TYPE>(fields: KEY[]): [[KEY], TYPE[KEY]][] {
    // @ts-ignore
    return fields.map((field) => [field, this.view(field)], this);
  }

  public valuesFor<KEY extends keyof TYPE>(fields: KEY[]): TYPE[KEY][] {
    return fields.map(this.view, this);
  }

  public pick<KEY extends keyof TYPE>(fields: KEY[]): Pick<TYPE, KEY> {
    // @ts-ignore
    return Object.fromEntries(fields.map((field) => [field, this.view(field)], this));
  }

  public omit<KEY extends keyof TYPE>(fields: KEY[]): Omit<TYPE, KEY> {
    // @ts-ignore
    return this.pick(without(fields, Object.keys(this._state)));
  }

  public watchEvery(watcher: Watcher<TYPE[keyof TYPE], keyof TYPE>) {
    this.globalWatchers.push(watcher);

    return () => {
      this.globalWatchers = without([watcher], this.globalWatchers);
    };
  }

  public watch<KEY extends keyof TYPE>(field: KEY, watcher: Watcher<TYPE[KEY], KEY>) {
    this.watchers[field] = (this.watchers[field] ?? []).concat([watcher]);

    return () => {
      this.watchers[field] = without([watcher], this.watchers[field]);
    };
  }

  public watchMany<KEY extends keyof TYPE>(fields: KEY[], watcher: Watcher<TYPE[KEY], KEY>) {
    const unwatchFunctions = fields.map((field) => this.watch(field, watcher), this);

    return () => unwatchFunctions.forEach(call);
  }

  @action.bound
  public set<KEY extends keyof TYPE>(fieldName: KEY, value: TYPE[KEY]): this {
    if (value === this._state[fieldName]) return this;

    if (this.locks[fieldName]) {
      if (process.env.NODE_ENV === "development") {
        console.warn(
          `editing of locked value ${String(fieldName)} is unavailable. Trying to change ${JSON.stringify(
            this._state[fieldName],
          )} to ${JSON.stringify(value)}`,
        );
      }
      return this;
    }

    this.triggerAllWatchers(fieldName, value);

    this._state[fieldName] = value;
    return this;
  }

  @action.bound
  public setFabric<KEY extends keyof TYPE>(fieldName: KEY) {
    return this.set.bind(this, fieldName);
  }

  public isLocked<KEY extends keyof TYPE>(key: KEY) {
    return this.locks[key];
  }

  @action.bound
  public setLock<KEY extends keyof TYPE>(key: KEY, flag: boolean) {
    this.locks[key] = flag;
    return this;
  }

  @action.bound
  public lock<KEY extends keyof TYPE>(key: KEY) {
    return this.setLock(key, true);
  }

  @action.bound
  public unlock<KEY extends keyof TYPE>(key: KEY) {
    return this.setLock(key, false);
  }

  @action.bound
  public over<KEY extends keyof TYPE>(fieldName: KEY, overrideFn: (prevState: TYPE[KEY]) => TYPE[KEY]) {
    this.set(fieldName, overrideFn(this.view(fieldName)));
    return this;
  }

  @action.bound
  public toggle<KEY extends TypedKeys<TYPE, boolean>>(fieldName: KEY) {
    // @ts-ignore
    this.over(fieldName, (prevState: boolean) => !prevState);
    return this;
  }

  @action.bound
  public mergeWith(payload: Partial<TYPE>) {
    this._state = Object.assign(this.snapshot, payload);
    return this;
  }

  @action.bound
  public clear() {
    this._state = clone(this.initialState);
    return this;
  }

  @computed
  public get snapshot(): TYPE {
    return toJS(this._state);
  }

  public get state(): Readonly<TYPE> {
    return this._state;
  }

  protected async transaction(mutation: (context: this) => void, asyncAction: (context: this) => Promise<void>) {
    return await this._transaction(mutation, asyncAction, false);
  }

  protected async transactionWithLock(
    mutation: (context: this) => void,
    asyncAction: (context: this) => Promise<void>,
  ) {
    return await this._transaction(mutation, asyncAction, true);
  }

  private triggerWatchers<KEY extends keyof TYPE>(
    watchers: Watcher<TYPE[KEY], KEY>[],
    fieldName: KEY,
    newValue: TYPE[KEY],
  ) {
    try {
      watchers.forEach((watcher) => {
        watcher(fieldName, newValue, this.view(fieldName));
      }, this);
    } catch (e) {
      console.error(e);
    }
  }

  private triggerFieldWatchers<KEY extends keyof TYPE>(fieldName: KEY, value: TYPE[KEY]) {
    this.triggerWatchers(this.watchers[fieldName], fieldName, value);
  }

  private triggerGlobalWatchers<KEY extends keyof TYPE>(fieldName: KEY, value: TYPE[KEY]) {
    this.triggerWatchers(this.globalWatchers, fieldName, value);
  }

  private triggerAllWatchers<KEY extends keyof TYPE>(fieldName: KEY, value: TYPE[KEY]) {
    this.triggerGlobalWatchers(fieldName, value);
    this.triggerFieldWatchers(fieldName, value);
  }

  private async _transaction<T>(
    mutation: (context: this) => void,
    asyncAction: (context: this) => Promise<T>,
    shouldLock?: boolean,
  ) {
    let entriesToRollback,
      exception,
      result = null as unknown as T;

    const oldValues = this.snapshot;

    try {
      mutation(this);

      entriesToRollback = difference(Object.entries(oldValues), Object.entries(this.snapshot));

      if (entriesToRollback.length === 0) return;

      if (shouldLock) {
        entriesToRollback.forEach(([key]) => this.setLock(key as keyof TYPE, true), this);
      }

      result = await asyncAction(this);
    } catch (e) {
      exception = e;
      if (entriesToRollback) {
        entriesToRollback.forEach(([key, value]) => this.set(key as keyof TYPE, value), this);
      }
    } finally {
      if (shouldLock && entriesToRollback) {
        entriesToRollback.forEach(([key]) => this.setLock(key as keyof TYPE, false), this);
      }
      if (exception) throw exception;
      return result;
    }
  }
}
