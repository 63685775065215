import React from "react";
import { theme } from "antd";
import classNames from "classnames";

import styles from "./index.module.scss";

interface PaperProps {
  padding: "sm" | "md" | "lg" | "xl";
  style?: React.CSSProperties;
}

function Paper({ padding, children, style }: React.PropsWithChildren<PaperProps>) {
  const { token } = theme.useToken();

  return (
    <div className={classNames(styles.wrapper, styles[padding])} style={{ borderColor: token.colorBorder, ...style }}>
      {children}
    </div>
  );
}

export default React.memo(Paper);
