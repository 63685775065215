import { array, boolean, field, number, string, succeed } from "jsonous";

import { dataDecoder, entityArrayDecoder, entityDecoder, fieldOrDefaultDecoder } from "./utils";

export const yearsDecoder = array(string);

export const clientsDecoder = array(
  succeed({})
    .assign("id", field("id", number))
    .assign("name", field("name", string))
    .assign("removed", field("removed", boolean)),
);

export const addressDecoder = succeed({})
  .assign("city", field("city", string))
  .assign("street", field("street", string))
  .assign("buildingNumber", field("buildingNumber", string))
  .assign("additionalInformation", fieldOrDefaultDecoder("additionalInformation", string, null!));

const clientAttributesDecoder = succeed({})
  .assign("name", field("name", string))
  .assign("removed", field("removed", boolean));

const clientDataDecoder = succeed({})
  .assign("id", field("id", number))
  .assign("attributes", field("attributes", clientAttributesDecoder));

const clientDecoder = succeed({}).assign("data", field("data", clientDataDecoder));

export const departmentFullAttributesDecoder = succeed({})
  .assign("name", field("name", string))
  .assign("removed", field("removed", boolean))
  .assign("client", field("client", clientDecoder))
  .assign("address", field("address", addressDecoder))
  .map((department) => ({
    ...department,
    client: { id: department.client.data.id, ...department.client.data.attributes },
  }));

const departmentFullDataDecoder = succeed({})
  .assign("id", field("id", number))
  .assign("attributes", field("attributes", departmentFullAttributesDecoder));

export const departmentFullDecoder = succeed({})
  .assign("data", field("data", departmentFullDataDecoder))
  .map((department) => ({ id: department.data.id, ...department.data.attributes }));

const clientInDepartmentDecoder = succeed({})
  .assign("id", field("id", number))
  .assign("name", field("name", string))
  .assign("removed", field("removed", boolean));

const defaultDepartmentDecoder = succeed({})
  .assign("id", field("id", number))
  .assign("code", field("code", string))
  .assign("name", field("name", string))
  .assign("removed", field("removed", boolean))
  .assign("address", field("address", addressDecoder))
  .assign("client", field("client", clientInDepartmentDecoder));

export const departmentsDecoder = array(defaultDepartmentDecoder);

export const usersFilterDecoder = array(
  succeed({})
    .assign("id", field("id", number))
    .assign("firstNameRu", field("firstNameRu", string))
    .assign("lastNameRu", field("lastNameRu", string))
    .assign("middleNameRu", fieldOrDefaultDecoder("middleNameRu", string, undefined))
    .assign("firstNameEn", field("firstNameEn", string))
    .assign("lastNameEn", field("lastNameEn", string))
    .assign("middleNameEn", fieldOrDefaultDecoder("middleNameEn", string, undefined))
    .assign("department", field("department", defaultDepartmentDecoder))
    .map((attributes) => ({
      department: attributes.department.name,
      id: attributes.id,
      ru: `${attributes.lastNameRu} ${attributes.firstNameRu} ${
        attributes.middleNameRu ? attributes.middleNameRu : ""
      }`,
      en: `${attributes.lastNameEn} ${attributes.firstNameEn} ${
        attributes.middleNameEn ? attributes.middleNameEn : ""
      }`,
    })),
);

const riskLevelDecoder = succeed({})
  .assign("id", field("id", number))
  .assign("ru", field("nameRu", string))
  .assign("en", field("nameEn", string))
  .map((riskLevel) => ({ ...riskLevel, id: String(riskLevel.id) }));

export const riskLevelsDecoder = array(riskLevelDecoder);

const riskDescriptionDecoder = succeed({})
  .assign("id", field("id", number))
  .assign("ru", field("nameRu", string))
  .assign("en", field("nameEn", string))
  .map((riskDescription) => ({ ...riskDescription, id: String(riskDescription.id) }));

export const riskDescriptionsDecoder = array(riskDescriptionDecoder);

const bestPracticeThemeDefaultAttributesDecoder = succeed({})
  .assign("id", field("id", number))
  .assign("ru", field("nameRu", string))
  .assign("en", field("nameEn", string))
  .map((theme) => ({ ...theme, id: String(theme.id) }));

export const bestPracticeThemesDefaultDecoder = array(bestPracticeThemeDefaultAttributesDecoder);

const bestPracticeThemeAttributesDecoder = succeed({})
  .assign("ru", field("nameRu", string))
  .assign("en", field("nameEn", string));

export const bestPracticeThemeDecoder = entityDecoder(bestPracticeThemeAttributesDecoder);

export const departmentTeamsDecoder = entityArrayDecoder(
  succeed({})
    .assign("leader", field("leader", dataDecoder(succeed({}).assign("id", field("id", number)))))
    .assign("members", field("members", dataDecoder(array(succeed({}).assign("id", field("id", number))))))
    .assign("department", field("department", dataDecoder(succeed({}).assign("id", field("id", number)))))
    .map((data) => ({
      ...data,
      leader: { id: data.leader.data.id.toString() },
      department: {id: data.department.data.id },
      members: data.members.data.map((member) => ({ id: member.id.toString() })),
    })),
);
