import { Inject, Service } from "typedi";

import { checklistDecoder, checklistInDataDecoder } from "../decoders";
import { METHODS, RequestManager } from "../requestManager";

@Service()
export class ChecklistTemplateApi {
  @Inject()
  private requestManager!: RequestManager;

  public async getActiveRulesChecklist() {
    return await this.requestManager.createRequest({
      url: `/api/activeRulesChecklist`,
      method: METHODS.GET,
      requestConfig: {},
      serverDataDecoder: checklistDecoder,
    })();
  }

  public async getRulesChecklistById(id: number) {
    return await this.requestManager.createRequest({
      url: `/api/checklist-rules-templates/${id}?populate=deep`,
      method: METHODS.GET,
      requestConfig: {},
      serverDataDecoder: checklistInDataDecoder,
    })();
  }

  public async getActiveCultureChecklist() {
    return await this.requestManager.createRequest({
      url: `/api/activeCultureChecklist`,
      method: METHODS.GET,
      requestConfig: {},
      serverDataDecoder: checklistDecoder,
    })();
  }

  public async getCultureChecklistById(id: number) {
    return await this.requestManager.createRequest({
      url: `/api/checklist-culture-templates/${id}?populate=deep`,
      method: METHODS.GET,
      requestConfig: {},
      serverDataDecoder: checklistInDataDecoder,
    })();
  }
}
