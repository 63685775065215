import { array, field, number, string, succeed } from "jsonous";

import { attributesDecoder, dataDecoder, fieldOrDefaultDecoder } from "./utils";

const questionDecoder = () =>
  succeed({})
    .assign("ru", field("questionRu", string))
    .assign("en", field("questionEn", string))
    .assign("id", field("id", number));

const answerDecoder = () =>
  succeed({})
    .assign("ru", field("answerRu", string))
    .assign("en", field("answerEn", string))
    .assign("id", field("id", number));

const checklistAnswersDecoder = () =>
  succeed({}).assign(
    "questions",
    field(
      "questions",
      array(
        questionDecoder().assign(
          "options",
          field(
            "answerOptions",
            array(
              answerDecoder().assign(
                "subquestion",
                fieldOrDefaultDecoder(
                  "subquestion",
                  questionDecoder().assign("options", field("options", array(answerDecoder()))),
                  null!,
                ),
              ),
            ),
          ),
        ),
      ),
    ),
  );

export const checklistDecoder = checklistAnswersDecoder().assign("id", field("id", number));

export const checklistInDataDecoder = dataDecoder(
  attributesDecoder(checklistAnswersDecoder()).assign("id", field("id", number)),
).map((data) => ({
  ...data.data.attributes,
  id: data.data.id,
}));
