import React from "react";
import { useTranslation } from "react-i18next";
import { App as AntdApp, ConfigProvider } from "antd";
import { Locale } from "antd/lib/locale";
import antdEnLocale from "antd/locale/en_US";
import antdRuLocale from "antd/locale/ru_RU";

import { useAppServices } from "shared";
import { AppLanguage } from "shared/api";
import { themeConfig } from "shared/config/theme";

const appLanguageAndAntdLocaleMap: Record<AppLanguage, Locale> = {
  [AppLanguage.ru]: antdRuLocale,
  [AppLanguage.en]: antdEnLocale,
};

const AppServicesInitializer = React.memo(() => {
  const staticFunction = AntdApp.useApp();
  const appServices = useAppServices();

  appServices.setMessageInstance(staticFunction.message);
  appServices.setNotificationInstance(staticFunction.notification);
  appServices.setModalInstance(staticFunction.modal);
  return null;
});

export const withAntD = (App: React.FC<React.PropsWithChildren>) => () => {
  const { i18n } = useTranslation();

  return (
    <ConfigProvider theme={themeConfig} locale={appLanguageAndAntdLocaleMap[i18n.language as AppLanguage]}>
      <AntdApp>
        <AppServicesInitializer />
        <App />
      </AntdApp>
    </ConfigProvider>
  );
};
