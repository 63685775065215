import { array, field, number, string, succeed } from "jsonous";

import { userDataDecoder } from "./common";
import { fullSecurityVisitDecoder } from "./securityVisitFull";
import {
  attributesDecoder,
  dataDecoder,
  fieldOrDefaultDecoder,
  filesInEntitiesDecoder,
  orDefaultDecoder,
} from "./utils";

export const areaForImprovmentDecoder = 
  attributesDecoder(
    succeed({})
      .assign("description", fieldOrDefaultDecoder("description", string, null!))
      .assign("title", fieldOrDefaultDecoder("title", string, null!))
      .assign("status", fieldOrDefaultDecoder("status", string, null!))
      .assign("createdAt", fieldOrDefaultDecoder("createdAt", string, null!))
      .assign("reasonCancellation", fieldOrDefaultDecoder("reasonCancellation", string, null!))
      .assign("resultsWorkMessage", fieldOrDefaultDecoder("resultsWorkMessage", string, null!))
      .assign(
        "executor",
        fieldOrDefaultDecoder(
          "executor",
          dataDecoder((attributesDecoder(userDataDecoder())).assign("id", field("id", number))),
          { data: null! }
        ),
      )
      .assign("files", fieldOrDefaultDecoder("files", filesInEntitiesDecoder, []))
      .assign("resultsWorkFiles", fieldOrDefaultDecoder("resultsWorkFiles", filesInEntitiesDecoder, []))
      .assign("safetyVisit", fieldOrDefaultDecoder("safety_visit", 
        fullSecurityVisitDecoder,
        { data: null! }
      ))
  )
  .assign("id", field("id", number))
  .map((area) => ({
    ...area.attributes, 
    executor: area.attributes.executor?.data?.id ? {
      id: area.attributes.executor.data.id.toString() ?? "",
      ru: `${area.attributes.executor.data.attributes.lastNameRu}
          ${area.attributes.executor.data.attributes.firstNameRu} 
          ${area.attributes.executor.data.attributes.middleNameRu 
            ?? area.attributes.executor.data.attributes.middleNameRu}`,
      en: `${area.attributes.executor.data.attributes.lastNameEn} ${area.attributes.executor.data.attributes.firstNameEn} ${area.attributes.executor.data.attributes.middleNameEn ? area.attributes.executor.data.attributes.middleNameEn : ""}`,
    } : null!,
    department: area.attributes.safetyVisit?.data?.attributes?.department?.name,
    safetyVisit: area.attributes.safetyVisit?.data?.attributes,
    id: area.id.toString(), 
  }))

export const areasForImprovmentFullDecoder = dataDecoder(areaForImprovmentDecoder);

export const areasForImporvmentListDecoder = dataDecoder(array(areaForImprovmentDecoder))
.assign(
  "meta",
  field(
    "meta",
    succeed({}).assign(
      "pagination",
      field(
        "pagination",
        succeed({}).assign("page", field("page", number)).assign("total", field("total", number)),
      ),
    ),
  ),
)

export const areaForImprovmentDetailDecoder = dataDecoder(areaForImprovmentDecoder);

export const firstAreasForImprovmentDateDecoder = orDefaultDecoder(string, null);
