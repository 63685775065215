import qs from "qs";
import { Inject, Service } from "typedi";

import { deleteSecurityReportDecoder, securityVisitItemDecoder, tableDataDecoder } from "../decoders";
import { IGetSecurityVisitsListFilters, ISecurityVisitListItem, ITableDto, Pagination } from "../interfaces";
import { METHODS, RequestManager } from "../requestManager";

@Service()
export class SecurityVisitsListApi {
  @Inject()
  private requestManager!: RequestManager;

  public async getSecurityVisitsListRequest({
    startDate,
    endDate,
    clients,
    departments,
    pageIndex,
    pageSize,
    status,
  }: IGetSecurityVisitsListFilters & Pagination): Promise<ITableDto<ISecurityVisitListItem>> {
    const query = qs.stringify(
      {
        sort: "visitDate:desc",
        filters: {
          status: {
            $eq: status,
          },
          visitDate: {
            $gte: startDate,
            $lt: endDate,
          },
          department: {
            id: {
              $in: departments,
            },
            removed: {
              $eq: false,
            },
            client: {
              id: {
                $in: clients,
              },
              removed: {
                $eq: false,
              },
            },
          },
        },
        populate: ["department", "department.client"],
        pagination: {
          pageSize,
          page: pageIndex,
        },
      },
      { skipNulls: true },
    );
    return await this.requestManager.createRequest({
      url: `/api/getSecurityVisitsList?${query}`,
      method: METHODS.GET,
      serverDataDecoder: tableDataDecoder(securityVisitItemDecoder),
    })();
  }

  public async deleteSecurityVisitRequest(id: string) {
    return await this.requestManager.createRequest({
      url: `/api/security-visits/${id}`,
      method: METHODS.DELETE,
      requestConfig: {},
      serverDataDecoder: deleteSecurityReportDecoder,
    })();
  }
}
