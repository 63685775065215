import { ThemeConfig } from "antd";

export const themeConfig: ThemeConfig = {
  components: {
    Typography: {
      sizeMarginHeadingVerticalEnd: 0,
      sizeMarginHeadingVerticalStart: 0,
    },
    Layout: {
      colorBgHeader: "transparent",
      colorBgBody: "transparent",
    },
    Table: {
      colorFillAlter: "#ececec",
    },
  },
  token: {
    colorBorderSecondary: "rgba(0,0,0,0.3)",
    colorBorder: "rgba(0,0,0,0.3)",
    fontFamily: "OpenSans, sans-serif",
  },
};
