import { isPureObject, isString } from "@worksolutions/utils";
import i18next from "i18next";
import Cookies from "js-cookie";
import { configure } from "mobx";
import { Container } from "typedi";

import { AppServices } from "shared";
import { AppRequestError, RequestManager } from "shared/api/requestManager";
import { AppRoutes } from "shared/config/appRoutes";

import reportWebVitals from "./reportWebVitals";

import "entities/i18n";

const appServices = Container.get(AppServices);

configure({ enforceActions: "never" });

reportWebVitals();

RequestManager.baseURL = process.env.REACT_APP_SERVER_URL as string;

const appRoutes = Container.get(AppRoutes);
RequestManager.beforeRequestMiddleware.push(({ config }) => {
  config.headers!["Accept-Language"] = i18next.language;
});

RequestManager.beforeRequestMiddleware.push(({ config }) => {
  const token = Cookies.get("token");
  if (!token) return;
  config.headers!.Authorization = token;
});

RequestManager.beforeErrorMiddleware.push(({ error }) => {
  if (error.statusCode === -1) {
    console.error(error);
    return null;
  }

  return error;
});

RequestManager.beforeErrorMiddleware.push(({ error, shareData }) => {
  try {
    if (!error.axiosError?.response?.data) return error;
    // @ts-ignore
    const { message: errors } = error.axiosError.response.data;
    if (isString(errors)) {
      shareData.decoded = true;
      return new AppRequestError({ message: errors, errors: {} }, error.statusCode, error.axiosError);
    }

    if (!errors || !isPureObject(errors) || Object.keys(errors).length === 0) return error;

    shareData.decoded = true;
    return new AppRequestError(
      {
        message: error.message,
        errors: Object.fromEntries(
          Object.entries(errors).map(([fieldName, error]) => [
            fieldName,
            isString(error) ? error : JSON.stringify(error),
          ]),
        ),
      },
      error.statusCode,
      error.axiosError,
    );
  } catch (e) {}

  return error;
});

RequestManager.beforeErrorMiddleware.push(({ error, shareData }) => {
  if (!shareData.decoded) return error;

  if (error.hasErrors()) {
    const errors = Object.entries(error.errors);
    errors.forEach(([errorKey, errorText]) =>
      appServices.notification.error({ message: `Ошибка при отправке запроса. Поле «${errorKey}»: ${errorText}` }),
    );
  } else {
    appServices.notification.error({ message: `Ошибка при отправке запроса: «${error.message}»` });
  }

  return null;
});

export const translateServerMessage = (error: AppRequestError) => {
  const url = error.axiosError?.config?.url;
  const urlWithoutQueryParams = url?.split("?")[0];
  const { statusCode } = error;
  const formattedUrl = urlWithoutQueryParams!
    .split("/")
    .filter((urlPart: string) => urlPart !== "api")
    .join(".");
  const errorString = `serverErrors${formattedUrl}.${statusCode}`;
  return i18next.t(errorString);
};

RequestManager.beforeErrorMiddleware.push(({ error }) => {
  const translatedErrorMessage = translateServerMessage(error);
  appServices.notification.error({ message: translatedErrorMessage });
  return error;
});

RequestManager.beforeErrorMiddleware.push(({ error }): any => {
  if (error.statusCode !== 401) return error;
  Cookies.remove("token");
  window.location.replace(appRoutes.getAuthUrl());
  return null;
});
