import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { GlobalOutlined } from "@ant-design/icons";
import { Select } from "antd";

import { useViewerModel, ViewerFullInfoModel } from "entities/viewer";

import { AppLanguage } from "../../shared/api";

import { SUPPORTED_LANGUAGE_ITEMS } from "./config";

function LanguageSwitcher() {
  const { i18n } = useTranslation();
  const viewerModel = useViewerModel();
  const currentLanguage = useMemo(() => viewerModel.state.interfaceLanguage, [viewerModel.state.interfaceLanguage]);
  const langSwitchHandlerAuth = useCallback(
    async (language: AppLanguage) => {
      await i18n.changeLanguage(language as AppLanguage);
      await ViewerFullInfoModel.updateUserData({ Language: language });
      await viewerModel.getViewer();
    },
    [i18n, viewerModel],
  );
  const langSwitchHandlerDefault = useCallback(
    async (language: AppLanguage) => {
      viewerModel.set("interfaceLanguage", language);
      await i18n.changeLanguage(language as AppLanguage);
    },
    [i18n, viewerModel],
  );
  useEffect(() => {
    i18n.changeLanguage(currentLanguage as AppLanguage);
  }, [currentLanguage, i18n]);
  return (
    <Select
      getPopupContainer={(triggerNode) => triggerNode.parentElement}
      placement="topLeft"
      defaultValue={currentLanguage}
      suffixIcon={<GlobalOutlined />}
      value={currentLanguage}
      options={SUPPORTED_LANGUAGE_ITEMS}
      onSelect={viewerModel.isViewerExist ? langSwitchHandlerAuth : langSwitchHandlerDefault}
    />
  );
}

export default observer(LanguageSwitcher);
