import { boolean, field, number, string, succeed } from "jsonous";

import { ApplicationStatus, ApplicationType } from "../interfaces";

import {
  commonUsersDecoder,
  departmentCommonDecoder,
  photosDecoder,
  riskDescriptionDecoder,
  riskLevelDecoder,
} from "./common";
import { departmentFullDecoder } from "./global";
import { attributesDecoder, dataDecoder, entityDecoder, enumDecoder, orDefaultDecoder } from "./utils";

const createApplicationAttributesDecoder = succeed({}).assign("applicationNumber", field("applicationNumber", string));

const createApplicationDecoder = attributesDecoder(createApplicationAttributesDecoder);

export const createApplicationDataDecoder = dataDecoder(createApplicationDecoder).map((securityVisit) => ({
  ...securityVisit.data.attributes,
}));

export const applicationResultDecoder = succeed({})
  .assign("id", field("id", number))
  .assign("isDangerResolved", field("isDangerResolved", boolean))
  .assign("isTheSignUp", field("isTheSignUp", boolean))
  .assign("areMeasuresHelped", field("areMeasuresHelped", boolean))
  .assign("appliedMeasures", field("appliedMeasures", string))
  .assign("completedActions", field("completedActions", string))
  .assign("resolvedDangerPhotos", field("resolvedDangerPhotos", photosDecoder));

const applicationAttributeDecoder = succeed({})
  .assign("type", field("type", enumDecoder(ApplicationType)))
  .assign("department", field("department", departmentCommonDecoder))
  .assign("status", field("status", enumDecoder(ApplicationStatus)))
  .assign("executors", field("executors", commonUsersDecoder))
  .assign("riskLevel", field("riskLevel", riskLevelDecoder))
  .assign("riskDescription", field("riskDescription", riskDescriptionDecoder))
  .assign("discrepancyDescription", field("discrepancyDescription", string))
  .assign("hazardLocation", field("hazardLocation", string))
  .assign("hazardDescription", field("hazardDescription", string))
  .assign("hazardPhotos", field("hazardPhotos", photosDecoder))
  .map((application) => ({
    ...application,
    executors: application.executors.map((executor) => ({
      id: String(executor.id),
      ru: `${executor.lastNameRu} ${executor.firstNameRu} ${executor.middleNameRu ? executor.middleNameRu : ""}`,
      en: `${executor.lastNameEn} ${executor.firstNameEn} ${executor.middleNameEn ? executor.middleNameEn : ""}`,
    })),
  }));

export const applicationDecoder = entityDecoder(applicationAttributeDecoder);

const fullApplicationAttributesDecoder = succeed({})
  .assign("type", field("type", enumDecoder(ApplicationType)))
  .assign("department", field("department", departmentFullDecoder))
  .assign("status", field("status", enumDecoder(ApplicationStatus)))
  .assign("executors", field("executors", commonUsersDecoder))
  .assign("riskLevel", field("riskLevel", riskLevelDecoder))
  .assign("riskDescription", field("riskDescription", riskDescriptionDecoder))
  .assign("discrepancyDescription", field("discrepancyDescription", string))
  .assign("hazardLocation", field("hazardLocation", string))
  .assign("hazardDescription", field("hazardDescription", string))
  .assign("hazardPhotos", field("hazardPhotos", photosDecoder))
  .assign("applicationResult", field("applicationResult", orDefaultDecoder(applicationResultDecoder, null)))
  .map((application) => ({
    ...application,
    executors: application.executors.map((executor) => ({
      id: String(executor.id),
      ru: `${executor.lastNameRu} ${executor.firstNameRu} ${executor.middleNameRu ? executor.middleNameRu : ""}`,
      en: `${executor.lastNameEn} ${executor.firstNameEn} ${executor.middleNameEn ? executor.middleNameEn : ""}`,
    })),
  }));

export const fullApplicationDecoder = entityDecoder(fullApplicationAttributesDecoder);
