import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  BarsOutlined,
  CheckOutlined,
  FundProjectionScreenOutlined,
  HomeOutlined,
  SecurityScanOutlined,
  SnippetsOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import { ItemType } from "antd/es/menu/hooks/useItems";

import { useViewerModel } from "entities/viewer";

import { UserRoles } from "shared/api";
import { useAppRoutes } from "shared/config/appRoutes";
import { isAllowed } from "shared/lib";

export function useMenuItems(): ItemType[] {
  const { t } = useTranslation();
  const appRoutes = useAppRoutes();
  const viewerModel = useViewerModel();

  return React.useMemo(() => {
    if (isAllowed(viewerModel.state.role!)) {
      const securityVisitItems = [
        {
          key: 1,
          label: (
            <Link to={appRoutes.getSecurityVisitMainUrl()}>{t("sideMenu.groups.securityVisits.items.mainScreen")}</Link>
          ),
          icon: <HomeOutlined />,
        },
        {
          key: 2,
          label: (
            <Link to={appRoutes.getSecurityVisitListUrl(true)}>
              {t("sideMenu.groups.securityVisits.items.securityVisits")}
            </Link>
          ),
          icon: <SecurityScanOutlined />,
        },
      ];

      const yaInspectorItems = [
        {
          key: 4,
          label: (
            <Link to={appRoutes.getYaInspectorMainUrl()}>{t("sideMenu.groups.yaInspector.items.mainScreen")}</Link>
          ),
          icon: <HomeOutlined />,
        },
        {
          key: 5,
          label: (
            <Link to={appRoutes.getYaInspectorApplicationMainUrl(true)}>
              {t("sideMenu.groups.yaInspector.items.applications")}
            </Link>
          ),
          icon: <BarsOutlined />,
        },
        {
          key: 6,
          label: (
            <Link to={appRoutes.getYaInspectorReportUrl(true)}>{t("sideMenu.groups.yaInspector.items.report")}</Link>
          ),
          icon: <SnippetsOutlined />,
        },
        {
          key: 7,
          label: (
            <Link to={appRoutes.getYaInspectorBestPracticesUrl(true)}>
              {t("sideMenu.groups.yaInspector.items.bestPractices")}
            </Link>
          ),
          icon: <CheckOutlined />,
        },
      ];

      if (viewerModel.state.role! === UserRoles.ADMINISTRATOR) {
        securityVisitItems.push({
          key: 3,
          label: (
            <Link to={appRoutes.getSecurityVisitReportUrl(true)}>
              {t("sideMenu.groups.securityVisits.items.report")}
            </Link>
          ),
          icon: <SnippetsOutlined />,
        });
        yaInspectorItems.push({
          key: 8,
          label: (
            <Link to={appRoutes.getYaInspectorChecklistsUrl(true)}>{t("sideMenu.groups.yaInspector.items.polls")}</Link>
          ),
          icon: <TeamOutlined />,
        });
        securityVisitItems.push({
          key: 9,
          label: (
            <Link to={appRoutes.getAreasForImrovmentListUrl(true)}>
              {t("sideMenu.groups.securityVisits.items.areasForImprovment")}
            </Link>
          ),
          icon: <FundProjectionScreenOutlined />,
        });
        securityVisitItems.push({
          key: 10,
          label: (
            <Link to={appRoutes.getAreaForImprovmentReportUrl(true)}>
              {t("sideMenu.groups.yaInspector.items.areasForImprovmentReport")}
            </Link>
          ),
          icon: <SnippetsOutlined />,
        });
      }

      return [
        {
          type: "group",
          label: t("sideMenu.groups.securityVisits.title"),
          children: securityVisitItems,
        },
        {
          type: "group",
          label: t("sideMenu.groups.yaInspector.title"),
          children: yaInspectorItems,
        },
      ];
    }

    return [
      {
        type: "group",
        label: t("sideMenu.groups.yaInspector.title"),
        children: [
          {
            key: 4,
            label: (
              <Link to={appRoutes.getYaInspectorMainUrl()}>{t("sideMenu.groups.yaInspector.items.mainScreen")}</Link>
            ),
            icon: <HomeOutlined />,
          },
          {
            key: 5,
            label: (
              <Link to={appRoutes.getYaInspectorApplicationMainUrl(true)}>
                {t("sideMenu.groups.yaInspector.items.applications")}
              </Link>
            ),
            icon: <BarsOutlined />,
          },
          {
            key: 6,
            label: (
              <Link to={appRoutes.getYaInspectorReportUrl(true)}>{t("sideMenu.groups.yaInspector.items.report")}</Link>
            ),
            icon: <SnippetsOutlined />,
          },
          {
            key: 7,
            label: (
              <Link to={appRoutes.getYaInspectorBestPracticesUrl(true)}>
                {t("sideMenu.groups.yaInspector.items.bestPractices")}
              </Link>
            ),
            icon: <CheckOutlined />,
          },
          {
            key: 8,
            label: (
              <Link to={appRoutes.getYaInspectorChecklistsUrl(true)}>
                {t("sideMenu.groups.yaInspector.items.polls")}
              </Link>
            ),
            icon: <TeamOutlined />,
          },
          ...(viewerModel.state.role! === UserRoles.LEADER
            ? [
                {
                  key: 9,
                  label: (
                    <Link to={appRoutes.getAreasForImrovmentListUrl(true)}>
                      {t("sideMenu.groups.securityVisits.items.areasForImprovment")}
                    </Link>
                  ),
                  icon: <FundProjectionScreenOutlined />,
                },
                {
                  key: 10,
                  label: (
                    <Link to={appRoutes.getAreaForImprovmentReportUrl(true)}>
                      {t("sideMenu.groups.yaInspector.items.areasForImprovmentReport")}
                    </Link>
                  ),
                  icon: <SnippetsOutlined />,
                },
              ]
            : []),
        ],
      },
    ];
  }, [appRoutes, t, viewerModel.state.role]);
}
